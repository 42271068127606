import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { Api } from "../../../../api";
import boneco from "../../../../assets/images/icones/boneco.png";
import bonecoEditar from "../../../../assets/images/icones/editar.png";
import card from "../../../../assets/images/icones/card.png";
import casa from "../../../../assets/images/icones/casa.png";
import dente from "../../../../assets/images/icones/dente.png";
import megafone from "../../../../assets/images/icones/megafone.png";
import estetoscopio from "../../../../assets/images/icones/estetoscopio.png";
import sino from "../../../../assets/images/icones/sino.png";
import carrinho from "../../../../assets/images/icones/carrinho.png";
import calculadora from "../../../../assets/images/icones/calculo.png";
import mao from "../../../../assets/images/icones/mao.png";
import empresasParceias from "../../../../assets/images/icones/empresas_parceiras.png";
import { FaWhatsapp } from "react-icons/fa";

import ferramentas_vendas from "../../../../assets/images/icones/ferramentas_vendas.png";
import material_divulgacao from "../../../../assets/images/icones/material_divulgacoes.png";
import auto_moto from "../../../../assets/images/icones/auto_moto.png";
import faca_cotacoes from "../../../../assets/images/icones/faca_cotacoes.png";
import cartao_beneficios from "../../../../assets/images/icones/cartao_beneficios.png";
import home from "../../../../assets/images/icones/home.png";
import material_vendas from "../../../../assets/images/icones/material_vendas.png";
import pat_rh from "../../../../assets/images/icones/pat_rh.png";
import pet from "../../../../assets/images/icones/pet.png";
import produtosEmpresas from "../../../../assets/images/icones/produtos_empresas.png";
import seja_parceiro from "../../../../assets/images/icones/seja_parceiro.png";
import treinamentos from "../../../../assets/images/icones/treinamentos.png";
import vendas_online from "../../../../assets/images/icones/vendas_online.png";
import uti_movel from "../../../../assets/images/icones/uti_movel.png";

import produtos_ramos_atuacao from "../../../../assets/images/icones/produtos_ramos_atuacao.png";
import viagem from "../../../../assets/images/icones/viagem.png";


import { Login } from "../Login";
import { MeusDados } from "../MeusDados";
import { googleLogout } from '@react-oauth/google';
import { GoGear } from "react-icons/go";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { BiDetail } from "react-icons/bi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { RxExit } from "react-icons/rx";
import { IoCarOutline } from "react-icons/io5";
import { BsHouses } from "react-icons/bs";
import { BiBuildingHouse } from "react-icons/bi";
import { SiYourtraveldottv } from "react-icons/si";
import { MdOutlineWorkOutline } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { LuDog } from "react-icons/lu";
import { MdListAlt } from "react-icons/md";
import { CiHospital1 } from "react-icons/ci";
import { MdChecklistRtl } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { FaRegCreditCard } from "react-icons/fa";
import { TfiAgenda } from "react-icons/tfi";

import { FaPlaneDeparture } from "react-icons/fa6";
import { PiForkKnifeBold } from "react-icons/pi";
import { LiaAmbulanceSolid } from "react-icons/lia";
import { IoSearch } from "react-icons/io5";
import React from "react";
import "./style.css";
import { Parceiro } from "../Parceiro";
import { baseUrlApp } from "../../../../api/baseUrl";
import { Modal } from "reactstrap";
function Drawer({ drawer, action }) {

  const [itemSize, setSize] = useState("");
  const [item, setItem] = useState("");
  const [modalDados, setModalDados] = useState();
  // const handler = (e, value) => {
  //   e.preventDefault();
  //   const getItems = document.querySelectorAll(`#${value} li`).length;
  //   if (getItems > 0) {
  //     if (item !== value) {
  //       setSize(`100%`);
  //       setItem(value);
  //     } else {
  //       setItem(
  //         escolha != 0 && (escolha == 1 || escolha == 2) ? "ferramentos" : ""
  //       );
  //     }
  //   }
  // };
  // const [itemSize, setSize] = useState("0px");
  // const [item, setItem] = useState("");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${20 * getItems}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };

  const getAuthUser = localStorage.getItem("authUser");

  const navigate = useNavigate();
  const [modalApagar, setModalApagar] = useState();
  const [estadosSaude, setEstadosSaude] = useState();
  const [estadosOdonto, setEstadosOdonto] = useState();
  const [loadingSaude, setLoadingSaude] = useState(false);
  const [loadingOdonto, setLoadingOdonto] = useState(false);
  const [escolha, setEscolha] = useState(0);
  const [escolhasub, setEscolhaSub] = useState(0);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalParceiro, setModalParceiro] = useState(false);
  const handleEstadosSaude = async () => {
    setLoadingSaude(true);
    let list = await Api.estadosSaude();
    setEstadosSaude(list.list);
    setLoadingSaude(false);
  };
  const handleEstadosOdonto = async () => {
    setLoadingOdonto(true);
    let list = await Api.estadosOdontologico();
    setEstadosOdonto(list.list);
    setLoadingOdonto(false);
  };
  useEffect(() => {
    handleEstadosSaude();
    handleEstadosOdonto();
  }, []);
  const handleLink = () => {
    window.open(`${baseUrlApp}/login/${obj.id}/${obj.token}`, "_blank");

  }
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const [comunicados, setComunicados] = useState('')
  const [treinamento, setTreinamento] = useState('')
  useEffect(() => {
    handleDados()
  }, [])
  const obj = getAuthUser == "undefined" || getAuthUser == null ||
    getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
  }, [obj])
  const handleDados = async () => {
    let response = await Api.blogs();
    let list = response?.list?.list
    let id = localStorage.getItem("id");
    if (id) {
      id = JSON.parse(id)
    }
    let listagem = list.filter((item) => (!item?.blogviews?.some((item2) => (item2?.iduser == id))))
    setComunicados(listagem?.length)
    let json = await Api.treinamentos();
    let lists = json?.list
    setTreinamento(lists?.length)
  };
  const [modal, setModal] = useState(false)
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${!modalLogin && drawer ? "active" : ""
          }`}
        style={{ width: '100%' }}
      ></div>

      <div className="offcanvas_menu" style={{ display: 'block' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${!modalLogin && drawer ? "active" : ""
                  }`}
                style={{ width: !modalLogin && drawer ? "290px" : "" }}
              >
                <a href="/"
                  className="offcanvas-brand text-center mb-40 d-block"
                  style={{ color: '#222222', float: 'right', marginTop: '-30px' }}
                  onClick={(e) => action(e)}  >
                  <span aria-hidden="true" style={{ color: '#222222' }} >&times;</span>
                </a>

                <div id="menu" >
                  <ul className="offcanvas_main_menu">
                    {!obj.nome && (

                      <li className="menu-item-has-children active" style={{ display: 'flex' }} >
                        {!obj.nome && (
                          <Link style={{ border: 'none', marginBottom: 0, fontWeight: 100 }} onClick={() => {
                            setModalLogin(true);
                            localStorage.setItem("authPagehist", '/ferramentas-de-venda');
                          }}>
                            <img src={boneco} style={{ width: 16, marginRight: 5, marginBottom: 5 }} />
                            Área do Corretor
                          </Link>
                        )}
                      </li>
                    )}
                    {obj.id && (
                      <li onClick={(e) => handler(e, "home")} id="home" className="menu-item-has-children active" >
                        <a href="#" style={{ border: 'none', marginBottom: 0 }}>

                          <span style={{ color: '#092140' }}>
                            <img src={boneco} style={{ width: 16, marginRight: 5, marginBottom: 5 }} />
                            Olá,{' '}
                          </span>
                          <span style={{ textTransform: 'capitalize', color: '#092140' }} >
                            {capitalizeFirstLetter(obj.nomefantasia ? obj.nomefantasia.split(' ')[0] : obj.nome.split(' ')[0])}
                          </span>


                          <span className="menu-expand" >
                            {item == "home" ?
                              <FaAngleDown />

                              :
                              <FaAngleRight />
                            }
                          </span>
                        </a>

                        <ul className="sub-menu" style={{
                          height: item === "home" ? itemSize : "0px",
                        }} >
                          <li>
                            <Link style={{ color: '#222222', border: 'none', marginBottom: 0 }} onClick={() => { setModalDados(true); }}>
                              <img src={bonecoEditar} style={{ width: 16, marginBottom: 4, marginRight: '4px' }} />
                              Meu Perfil</Link>
                          </li>
                          {obj.permissao != 5 &&
                            <li >
                              <Link style={{ textAlign: 'left', border: 'none', color: '#535353', marginBottom: 0 }} onClick={handleLink} >
                                <GoGear size={15} style={{ marginRight: 4 }} color="#5c5c5c" />

                                Gerenciar </Link>
                            </li>
                          }

                        </ul>
                      </li>
                    )}


                    <li className="menu-item-has-children active" style={{ fontWeight: 100 }} >
                      <Link to="/" style={{ border: 'none', marginBottom: 0, fontWeight: 100 }} onClick={(e) => {
                        action(e)
                        navigate('/');
                      }
                      } ><img src={home} style={{ width: 16, marginBottom: 4, marginRight: '2px' }} /> Inicio</Link>
                    </li>

                    <li className="menu-item-has-children active" >
                      <Link to={obj.id ? "/ferramentas-de-venda" : '#'} style={{ marginBottom: 2, border: 'none', fontWeight: 100, color: '#535353' }} onClick={() => { !obj.id && setModalLogin(true) }} >
                        <img src={ferramentas_vendas} style={{ width: 16, marginRight: 2, marginBottom: 4 }} />

                        Ferramenta de Vendas
                      </Link>
                      {/*   */}
                    </li>



                    <li className="menu-item-has-children active" >
                      <Link to={"/comunicados"} style={{ marginBottom: 2, border: 'none', fontWeight: 100, color: '#535353' }} >

                        <img src={sino} style={{ width: 16, marginBottom: 4, marginRight: 2 }} />
                        {comunicados ?
                          <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{comunicados}</div>
                          : ""}
                        Comunicados
                      </Link>
                      {/*   */}
                    </li>
                    <li className="menu-item-has-children active" >
                      <Link to={obj.id ? "/treinamentos" : '#'} style={{ marginBottom: 2, border: 'none', fontWeight: 100, color: '#535353' }} onClick={() => { !obj.id && setModalLogin(true) }}  >
                        <img src={treinamentos} style={{ width: 16, marginBottom: 2, marginRight: '2px', fontWeight: 100 }} />
                        {treinamento ?
                          <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{treinamento}</div>

                          : ""}
                        Agenda de Treinamentos
                      </Link>
                      {/*   */}
                    </li>
                    <li className="menu-item-has-children active" >
                      <Link to={obj.id ? "/materiais" : '#'} style={{ marginBottom: 2, border: 'none', fontWeight: 100, color: '#535353' }} onClick={() => {
                        if (!obj.id) {
                          setModalLogin(true)
                          localStorage.setItem("authPagehist", '/materiais');
                        }
                      }}  >
                        <img src={material_divulgacao} style={{ width: 16, marginBottom: 2, marginRight: '2px', fontWeight: 100 }} />
                        Materiais de Divulgação</Link>
                    </li>




                    <li onClick={(e) => handler(e, "tabelaMateriaisVendas")} id="tabelaMateriaisVendas" className="menu-item-has-children active" style={{ fontWeight: 100 }}>
                      <a style={{ border: 'none', marginBottom: 0, fontWeight: 100, color: '#5c5c5c' }} >
                        <img src={material_vendas} style={{ width: 16, marginBottom: 2, marginRight: '2px', fontWeight: 100 }} />
                        Tabelas e Materiais de Vendas
                        <span className="menu-expand" >
                          {item == "tabelaMateriaisVendas" ?
                            <FaAngleDown />

                            :
                            <FaAngleRight />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "tabelaMateriaisVendas" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active" >
                          <Link to={"/plano/saude"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }}>
                            <img src={estetoscopio} style={{ width: 16, marginBottom: 4 }} />

                            Planos de Saúde</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/odontologico"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }}><img src={dente} style={{ width: 13, marginBottom: 4 }} /> Planos Odontológicos
                          </Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <a href={"/plano/odontologico"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }}>
                            <img src={uti_movel} style={{ width: 18, marginBottom: 2, marginRight: 2 }} />


                            UTI Móvel Emergências 24h
                          </a>
                        </li>

                        <li className="menu-item-has-children active">
                          <a href={"/plano/pet"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }}>

                            <img src={pet} style={{ width: 16, marginBottom: 4, marginRight: 2 }} />
                            Planos Pet
                          </a>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/pah"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }}>


                            <img src={cartao_beneficios} style={{ width: 16, marginRight: 2, marginBottom: 2 }} />
                            Cartões de Benefícios
                          </Link>
                        </li>
                        {/* <li className="menu-item-has-children active">
                          <Link to={"/plano/rh"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }}>


                            <img src={pat_rh} style={{ width: 18, marginRight: 2, marginBottom: 2 }} />
                            PAT e Benefícios para RH
                          </Link>
                        </li> */}

                        {/* <li className="menu-item-has-children active">
                          <Link to={"/plano/consorcio" }  style={{ border: 'none', marginBottom: 2 }}>
                          <GrMoney style={{marginRight:3}} />
                           Consórcios</Link>
                        </li> */}

                      </ul>
                    </li>


                    <li onClick={(e) => handler(e, "cotacoes")} id="cotacoes" className="menu-item-has-children active" >
                      <a style={{ border: 'none', color: '#535353', marginBottom: 0, fontWeight: 100 }} >
                        <img src={faca_cotacoes} style={{ width: 18, marginBottom: 4, marginRight: 2 }} />
                        Faça Suas Cotações
                        <span className="menu-expand" >
                          {item == "cotacoes" ?
                            <FaAngleDown />

                            :
                            <FaAngleRight />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "cotacoes" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active">
                          <a href={obj.id ? `/cotacoes/saude` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/cotacoes/saude');
                            }
                          }}><img src={estetoscopio} style={{ width: 16, marginBottom: 4 }} /> Multicálculo Saúde</a>
                        </li>
                        <li className="menu-item-has-children active">
                          <a href={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/cotacoes/odontologico');
                            }
                          }}
                          ><img src={dente} style={{ width: 16, marginBottom: 4 }} /> Multicálculo Odonto</a>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/tabela` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/tabela');
                            }
                          }}
                          ><img src={card} style={{ width: 16, marginBottom: 4, border: 'none' }} /> Tabelas Personalizadas</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/cotacoes/odontologico');
                            }
                          }}>


                            <img src={auto_moto} style={{ width: 16, marginRight: 2, marginBottom: 4, border: 'none' }} />

                            Multicálculo Auto e Moto</Link>
                        </li>


                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/cotacoes/odontologico');
                            }
                          }}>
                            <BsHouses size={15} style={{ marginRight: 4 }} color="#5c5c5c" />Multicálculo Residencial</Link>
                        </li>

                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/cotacoes/odontologico');
                            }
                          }}
                          >
                            <BiBuildingHouse size={15} style={{ marginRight: 4 }} color="#5c5c5c" /> Multicálculo Condomínio
                          </Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/cotacoes/odontologico');
                            }
                          }}>
                            <img src={viagem} style={{ width: 16, marginBottom: 2, marginRight: 2 }} />
                            Seguro Viagem
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li onClick={(e) => handler(e, "vendas")} id="vendas" className="menu-item-has-children active" >
                      <a style={{ border: 'none', color: '#535353', marginBottom: 0, color: '#535353' }} ><img src={vendas_online} style={{ width: 16, marginBottom: 4, marginRight: 2, fontWeight: 100 }} /> Links de Vendas Online
                        <span className="menu-expand" >
                          {item == "vendas" ?
                            <FaAngleDown />

                            :
                            <FaAngleRight />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "vendas" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active">
                          <Link to={`/vendaOnline`} style={{ border: 'none', marginBottom: 2, color: '#535353' }} ><img src={estetoscopio} style={{ width: 16, marginBottom: 4 }} />
                            Planos de Saúde
                          </Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={`/vendaOnline`} style={{ border: 'none', color: '#535353' }}  ><img src={dente} style={{ width: 16, border: 'none', marginBottom: 2, borderBottom: 'none', fontWeight: 100 }} /> Planos Odontológicos</Link>
                        </li>
                      </ul>
                    </li>


                    <li onClick={(e) => handler(e, "produtos")} id="produtos" className="menu-item-has-children active" >
                      <a style={{ border: 'none', color: '#535353', marginBottom: 0 }} >

                        <img src={produtosEmpresas} style={{ width: 16, marginRight: 2, marginBottom: 2 }} />

                        Produtos e Empresas
                        <span className="menu-expand" >
                          {item == "produtos" ?
                            <FaAngleDown />

                            :
                            <FaAngleRight />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "produtos" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active" >
                          <Link to={obj.id ? "/produtos" : '#'} style={{ marginBottom: 2, border: 'none', fontWeight: 100, color: '#535353' }} onClick={() => { !obj.id && setModalLogin(true) }} >
                            <IoSearch size={15} style={{ marginRight: 2 }} color="#5c5c5c" />
                            Buscar Produtos
                          </Link>
                          {/*   */}
                        </li>
                        <li className="menu-item-has-children active" >
                          <Link to={"/ramos"} style={{ border: 'none', marginBottom: 2, fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/ramos');
                            }
                          }}>

                            <img src={produtos_ramos_atuacao} style={{ width: 16, marginBottom: 2 }} />

                            Ramos de Atuação</Link>
                        </li>
                        <li className="menu-item-has-children active" >
                          <Link to={"/empresas"} style={{ marginBottom: 2, border: 'none', fontWeight: 100 }} onClick={() => {
                            if (!obj.id) {
                              setModalLogin(true)
                              localStorage.setItem("authPagehist", '/empresas');
                            }
                          }}  >
                            <img src={empresasParceias} style={{ width: 16, marginBottom: 4 }} />


                            Empresas Parceiras</Link>
                        </li>

                      </ul>
                      <li className="menu-item-has-children active" >
                        <a onClick={() => {
                          window.open("https://api.whatsapp.com/send/?phone=55800060706&text=Quero+ser+um+Corretor+Parceiro&type=phone_number&app_absent=0", "_blank");
                        }} style={{ marginBottom: 2, border: 'none', color: '#535353' }}  >
                          <img src={seja_parceiro} style={{ width: 16, marginBottom: 4, marginRight: 2, fontWeight: 100 }} />
                          Seja Nosso(a) Parceiro(a)
                        </a>
                        {/*   */}
                      </li>

                      <li className="menu-item-has-children active" >
                        <a onClick={() => {
                          window.open("https://api.whatsapp.com/send/?phone=55800060706&text=&type=phone_number&app_absent=0", "_blank");
                        }} style={{ marginBottom: 2, border: 'none', color: '#535353' }}  >

                          <FaWhatsapp size={15} style={{ marginRight: 2 }} color="#5c5c5c" />
                          Fale Conosco
                        </a>
                        {/*   */}
                      </li>

                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={() => {
                          localStorage.setItem("authUser", "{}");
                          window.location.href = '/'
                          googleLogout();
                        }}
                        style={{ textAlign: 'left', color: '#535353', marginBottom: 0, border: 'none', fontWeight: 100 }}
                      >
                        <RxExit size={15} style={{ marginRight: 4 }} color="#5c5c5c" />


                        Sair
                      </a>
                    </li>



                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
      <Parceiro modalDados={modalParceiro} setModalDados={setModalParceiro} />
      <MeusDados modalDados={modalDados} setModalDados={setModalDados} />

      <Modal isOpen={modal} size='md' centered={true}>
        <div className="modal-header" style={{ border: '0px' }}>
          <button type="button" onClick={() => { setModal(false) }} className="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ border: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Em breve.</div>
        </div>
        <div className="modal-footer" style={{ border: '0px' }}>

        </div>
      </Modal>
    </>
  );
}

export default Drawer;
