import React from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function ServiceOne() {
  const { estado, plano } = useParams();
 
  return (
    <Layouts
      style={{ fontFamily: "Open Sans, sans-serif" ,textTransform:'none'}}
      pageTitle={`${plano == "saude" ? "Planos de Saúde" : plano=='odontologico'?"Planos Odontologicos":plano=="pet"?'Planos Pet':plano=="rh"?"Pat e benefícios para RH":plano=="pah"?"Planos de benefício de pré atendimento hospitalar":""}`}
      
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: `Buscar Produto`, path: "/ramos" },
      ]}
    >
      <Dados plano={plano} estado={estado} />
    </Layouts>
  );
}
