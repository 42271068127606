import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Info from "./info";
export default function DesktopVisualizar({ datas }) {


  const [fisico, setFisico] = useState([]);
  const [juridico, setJuridico] = useState([]);
  const [item2, setItem] = useState("");

  const handleFiltro = () => {
    let fisica = datas.valores?.filter((item) => item.filter == "fornecedor");

    setFisico(fisica);
    let juridico = datas.valores?.filter((item) => item.filter == "ramo");
   
    setJuridico(juridico);
  };
  useEffect(() => {
    handleFiltro();
  }, [datas.valores]);
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value}`).length;
    if (getItems > 0) {
      if (item2 !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  return (
    <section className="portfolio-section  rpt-95 pb-90 rpb-70">
      <div className="container">
        <div className="row">
        <div className="col-md-6 col-xl-6">
            <button
              className="btn btn-primary"
              style={{
                width: "100%",
                backgroundColor: "#4180D6",
                border: "none", fontWeight: 100
              }}
            >
              Produtos
            </button>
            {juridico &&
              juridico.length > 0 &&
              juridico.map((item) => (
                <a
                  key={item.id}
                  className={`col-lg-4 col-sm-6 item `}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="feature-item   style-three "
                    style={{ marginBottom: 0, padding: 10 }}
                  >
                    <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                      <Link
                        className="menu-item-has-children active"
                        style={{
                          fontFamily: "Open Sans, sans-serif",
                          marginLeft: 10,
                          fontSize: 15
                        }}
                        onClick={(e) => handler(e, "operadora" + item.id)}
                        id={"operadora" + item.id}
                      >
                        <div style={{ float: 'left',overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',display:'flex',alignItems:'center' }}>
                          <img style={{ width: "35px"}}
                            src={`https://server.corpar.com.br/imagens/${item.image}`}/>
                          <a href="#" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4,width:160}} >
                            {item.title}
                          </a>
                        </div>
                        <div style={{ float: 'right' }}>
                          <span
                            className="menu-expand"

                          >
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </Link>
                      <div
                        className="sub-menu"
                        style={{
                          display:
                            item2 == "operadora" + item.id ? "block" : "none",
                        }}
                      >
                        
                        <Info tipos={item.tipos} />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>
          <div className="col-md-6 col-xl-6">
            <button
              className="btn btn-primary"
              style={{
                width: "100%",
                backgroundColor: "#4180D6",
                border: "none", fontWeight: 100
              }}
            >
              Fornecedor
            </button>
            {fisico &&
              fisico.length > 0 &&
              fisico?.map((item) => (
                <a
                  key={item.id}
                  className={`col-lg-4 col-sm-6 item `}
                  style={{ cursor: "pointer", marginBottom: 10 }}
                >
                  <div
                    className="feature-item   style-three "
                    style={{ marginBottom: 0, padding: 10 }}
                  >
                    <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                      <Link className="menu-item-has-children active" style={{ fontFamily: "Open Sans, sans-serif", marginLeft: 10, fontSize: 15 }} onClick={(e) => handler(e, "operadora" + item.id)} id={"operadora" + item.id} >
                        <div style={{ float: 'left', width: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <img
                            className="w-10"
                            style={{
                              width: "70px",
                              marginRight: "5px",
                              textAlign: 'left'
                            }}
                            src={`https://server.corpar.com.br/imagens/${item.image}`}
                            alt="Testimonial"
                          />
                          <a href="#" >
                            {item.title}
                          </a>
                        </div>
                        <div style={{ float: 'right' }}>
                          <span
                            className="menu-expand"

                          >
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </Link>
                      <div
                        className="sub-menu"
                        style={{
                          display:
                            item2 == "operadora" + item.id ? "block" : "none"
                        }}
                      >
                        
                        <Info tipos={item.tipos} />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>
        
        </div>
        <div className="row align-items-center  portfolio-wrap"></div>
      </div>
    </section>
  );
}
