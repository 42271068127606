import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
// import email from "../../assets/icones/email.png"
import { MdOutlineEmail as Email } from "react-icons/md";
import { IoLogoWhatsapp as Wpp } from "react-icons/io5";
import { FiPhone as Tel } from "react-icons/fi";
import ReactToPrint from "react-to-print";
import * as htmlToImage from 'html-to-image';
import { Modal } from "reactstrap";
import { LuPrinter } from "react-icons/lu";
import { RiDownloadCloudLine } from "react-icons/ri";
import { BsEye } from "react-icons/bs";
import { baseUrlApi } from "../../api/baseUrl";

export default function Divulgacao({ datas, id}) {
    const getAuthUser = localStorage.getItem("authUser");
    const obj =
        getAuthUser == "undefined" ||
            getAuthUser == null ||
            getAuthUser == "[object Object]"
            ? ""
            : JSON.parse(getAuthUser ? getAuthUser : "");
    useEffect(() => {
        const obj =
            getAuthUser == "undefined" ||
                getAuthUser == null ||
                getAuthUser == "[object Object]"
                ? ""
                : JSON.parse(getAuthUser ? getAuthUser : "");

    }, [obj])
   
    const openPrintDialog = (index, id) => {
        const divToHide = document.querySelector(`div[id="${index}"]`);
        if (divToHide) {
            divToHide.style.display = "none";
        }

        const element = document.querySelector(`a[id="${id}"] #imgVisuContaner`);
        if (element) {
            htmlToImage.toPng(element)
                .then(function (dataUrl) {
                    const printWindow = window;
                    if (printWindow) {
                        printWindow.document.write(`
                        <html>
                        <head>
                            <title>Imagem para Impressão</title>
                            <style>
                                body {
                                    margin: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100vh;
                                }
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            </style>
                        </head>
                        <body>
                            <img src="${dataUrl}" alt="Imagem para Impressão" />
                            <script>
                                window.onload = function() {
                                    window.print();
                                    window.close();
                                
                                }
                            </script>
                        </body>
                        </html>
                    `);
                        printWindow.document.close(); // Fechamos o documento para garantir que o script seja executado corretamente
                        printWindow.onload = function () {
                            printWindow.print();
                            printWindow.close();
                            window.location.href = `${process.env.REACT_APP_SITEWEB}/materiais`
                        };
                    } else {
                        alert('Falha ao abrir a caixa de diálogo de impressão. Verifique se as janelas pop-up estão desbloqueadas.');
                    }
                    document.querySelector(`div[id="${index}"]`).style.display = "flex"
                })
                .catch(function (error) {
                    console.error('Erro ao baixar a imagem:', error);
                    document.querySelector(`div[id="${index}"]`).style.display = "flex"
                });


        }
    };




    const canvasRef = useRef(null);
    const imgRef = useRef(null);
    const divRef = useRef(null);
    useEffect(() => {
        const divCanvas = divRef.current;
        const img = imgRef.current;

        if (img) {
            function resizeCanvas() {

                divCanvas.style.width = img.width + 'px';
                divCanvas.style.height = img.height + 'px';
            }

            img.onload = resizeCanvas; // Chama resizeCanvas quando a imagem for carregada
            resizeCanvas(); // Chama resizeCanvas uma vez para definir o tamanho inicial do canvas

            return () => {
                img.onload = null;
            };
        }
    }, [imgRef.current]);
    const [items,setItems] = useState()
    const [modal,setModal] = useState()
    const handleClick = (item) =>{
        setItems(item)
        setModal(true)
    }
    const modalRef = useRef(null);


    const handleClickOutside = (event) => {
        const modalElement = document.getElementById('modalElementId');
        if (modalElement && !modalElement?.contains(event.target)) {
            setModal(false)
        }
    };
    useEffect(() => {
        if (modal) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            // Remove o evento de clique quando o modal é fechado
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modal]);
 
    return (
        <section className="portfolio-section pt-50 rpt-70 pb-30 rpb-50">
            <div className="container" >
                <div className="row align-items-center  portfolio-wrap">
                    {datas &&
                        datas.length > 0 &&
                        datas.map((item, index) => (
                            <a key={item.id} id={item.id} ref={divRef} className={`col-lg-3 col-sm-6 item `} style={{ textDecoration: 'none' ,marginTop:50}} >

                                <div className="style-three " style={{ cursor: "pointer"}} >
                                    <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto" }}>



                                        <Row id="imgVisuContaner">
                                            <Col lg={12}>
                                                <div id={index} style={{ cursor: "pointer", display: 'flex', position: "absolute", top: -20, backgroundColor: '#fff', justifyItems: 'center', alignItems: 'center', width: '88%', right:12}}>

                                                    <div>
                                                        <RiDownloadCloudLine size={18} onClick={() => { downloadImage(index, item.id) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                                                    </div>
                                                    <div>
                                                        <LuPrinter size={18} onClick={() => { openPrintDialog(index, item.id) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                                                    </div>
                                                    <div>
                                                        <BsEye size={18} onClick={() => { handleClick( item) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                                                    </div>
                                                </div>
                                                {/* <CompartilharRedesSociais id={item.id}  titulo={'Teste'} /> */}
                                                <div onClick={() => { handleClick( item) }} style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',cursor:'pointer'}}>

                                                 

                                                  
                                                    <img id='imgVisu' ref={imgRef} src={`https://server.corpar.com.br/imagens/${item?.imagem}`} style={{ width: '92%', height: 280 }} />

                                                    <div ref={canvasRef} id='canvas'
                                                        style={{   width: '83%',height: 100, backgroundColor: '#fff', border: '1px solid #ccc', textAlign: 'center', bottom: 0, height: '65px', fontSize: item.tipo == '2' ? '8px' : '10px', position:'absolute',top:'215px',display:'flex',justifyContent:'center',alignItems:'center' }}  >

                                                        <div style={{ display: obj.foto && 'flex', justifyItems: 'center', alignItems: 'center' }} >
                                                            <div style={{ display: obj.foto && 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 10 }}>
                                                                {obj.foto &&
                                                                    <div style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                                                                        <img src={`https://server.corpar.com.br/imagens/${obj.foto}`} width={100} style={{ margin: 'auto', justifyItems: 'center', alignItems: 'center' }} />

                                                                    </div>
                                                                }
                                                                <div style={{ flex: 2 }}>
                                                                    <p style={{ fontSize: 13, textAlign: 'left', marginBottom: 0, marginTop: '-1px', textAlign: !obj.foto ? 'center' : 'left' }}> {`${obj.nomefantasia?((obj.nomefantasia.split(' ')[0] || "") +' '+(obj.nomefantasia.split(' ')[1]||"")): (obj.nome? (obj.nome.split(' ')[0]|| "") +' '+  (obj.nome.split(' ')[1] || ""):'') }`}</p>



                                                                    <p style={{ fontSize: 13, textAlign: 'left', marginBottom: 0, marginTop: '-10px', textAlign: !obj.foto ? 'center' : 'left' }}>{obj.celular && (obj.whatsapp ? <Wpp size={12} /> : <Tel size={12} />)} {obj.celular && `(${obj.celular[0]}${obj.celular[1]}) ${obj.celular[2]}${obj.celular[3]}${obj.celular[4]}${obj.celular[5]}${obj.celular[6]}-${obj.celular[7]}${obj.celular[8]}${obj.celular[9]}${obj.celular[10]}`}</p>

                                                                    <p style={{ fontSize: 9, textAlign: 'left', marginBottom: 0, marginTop: '-10px', textAlign: !obj.foto ? 'center' : 'left' }}><Email size={12} /> {obj.email}</p>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        {/* <Row style={{ textAlign: 'left', marginTop: 5 }} >
                                                            <Col lg={4} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 3, marginLeft: 8 }} >
                                                                {obj.foto ?
                                                                    <img src={`${baseUrlApi}/imagens/${obj.foto}`} width={100} style={{ margin: 'auto' }} />
                                                                    :
                                                                    <img src={logo} width={100} style={{ margin: 'auto' }} />}
                                                            </Col>
                                                            <Col lg={8} style={{ marginLeft: -30 }} >
                                                                <Col sm lg={12} style={{ lineHeight: 2 }}>
                                                                    <span style={{ fontSize: 13, lineHeight: 0 }}> {`${obj.nome.split(' ')[0]} ${obj.nome.split(' ')[1] ? obj.nome.split(' ')[1] : ''}`}</span>
                                                                </Col>
                                                                <Col lg={12} style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ fontSize: 13, lineHeight: 0 }}>{obj.whatsapp ? <Wpp size={12} /> : <Tel size={12} />} {obj.celular}</span>
                                                                </Col>
                                                                <Col lg={12} style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ fontSize: 10, lineHeight: 0 }}><Email size={12} /> {obj.email}</span>
                                                                </Col>
                                                            </Col>

                                                        </Row> */}


                                                    </div>
                                                   
                                                </div>

                                            </Col>
                                        </Row>




                                    </div>

                                </div>
                            </a>
                        ))}


                </div>
            </div>
            <Modal isOpen={modal} centered={false} id="modalElementId" >
              
                <div className="modal-body" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <button type="button"
                        onClick={() => {
                            setModal(false);
                        }} 
                        className="close" data-dismiss="modal" aria-label="Close" style={{position:'absolute',top:0,right:5,padding:5,backgroundColor:'#fff',borderRadius:6}} >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div style={{height:350}} >

                        <img id='imgVisu' ref={imgRef} src={`https://server.corpar.com.br/imagens/${items?.imagem}`} style={{ width: '100%'}} />

                        <div ref={canvasRef} id='canvas'
                            style={{ width: '100%', height: 100, backgroundColor: '#fff', border: '1px solid #ccc', textAlign: 'center', position: 'relative',top:'-90px', bottom: 0, height: '90px', fontSize: items?.tipo == '2' ? '8px' : '10px',display:'flex',justifyContent:'center',alignItems:'center' }}  >

                            <div style={{ display: obj.foto && 'flex', justifyItems: 'center', alignItems: 'center' }} >
                                <div style={{ display: obj.foto && 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 10 }}>
                                    {obj.foto &&
                                        <div style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                                            <img src={`https://server.corpar.com.br/imagens/${obj.foto}`} width={100} style={{ margin: 'auto', justifyItems: 'center', alignItems: 'center' }} />
                                        </div>
                                    }
                                    <div style={{ flex: 2 }}>
                                        <p style={{ fontSize: 16, textAlign: 'left', marginBottom: 0, marginTop: '-1px', textAlign: !obj.foto ? 'center' : 'left' }}> {`${obj.nomefantasia}`}</p>
                                        <p style={{ fontSize: 16, textAlign: 'left', marginBottom: 0, marginTop: '-10px', textAlign: !obj.foto ? 'center' : 'left' }}>{obj?.celular && (obj.whatsapp ? <Wpp size={12} /> : <Tel size={12} />)} { obj?.celular && `(${obj?.celular[0]}${obj.celular[1]}) ${obj.celular[2]}${obj.celular[3]}${obj.celular[4]}${obj.celular[5]}${obj.celular[6]}-${obj.celular[7]}${obj.celular[8]}${obj.celular[9]}${obj.celular[10]}`}</p>
                                        <p style={{ fontSize: 13, textAlign: 'left', marginBottom: 0, marginTop: '-10px', textAlign: !obj.foto ? 'center' : 'left' }}><Email size={12} /> {obj.email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </section>
    );
}
