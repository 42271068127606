import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Modal, Row } from "reactstrap";
import Info from "./info";
export default function MainSection({ datas, tipoRamo }) {
  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState("fisica");
  const [escolha, setEscolha] = useState("");
  const [dados, setDados] = useState([]);
  const { estado, plano } = useParams();
  const [modalAviso, setModalAviso] = useState(false);
  const handleFiltro = (val) => {
    if (datas.valores?.length > 0) {
      if (tipoRamo == 'Odonto-Saude') {
        let fisica = []
        let juridico = []
        if (val == "PF") {
          fisica = datas.valores?.filter((item) => item.filter == "PF");
          setDados(fisica);
        } else if (val == "PJ") {
          juridico = datas.valores?.filter((item) => item.filter == "PJ");
          setDados(juridico);
        } else {
          fisica = datas.valores?.filter((item) => item.filter == "PF");
          juridico = datas.valores?.filter((item) => item.filter == "PJ");
          const combinado = [...fisica, ...juridico];
          setDados(combinado);
        }

        if (fisica.length > 0) {
          setEscolha("PF");
        } else if (juridico.length > 0) {
          setEscolha("PJ");
        }

      } else {
        let valores = datas.valores;
        setDados(valores);
      }
    }


  };

  const handleDados = () => {
    handleFiltro();
  };

  useEffect(() => {
    handleDados();
  }, [datas, escolha]);
  const [link, setLink] = useState("");
  const handleAviso = (item) => {
    setModalAviso(true);
    setLink(item.link);
  };
  const [modalMaterial, setModalMaterial] = useState(false);
  const [id, setId] = useState("");
  const handleMaterial = (id) => {
    setModalMaterial(true);
    setId(id);
  };
  return (
    <section className="portfolio-section pt-10 rpt-95 pb-90 rpb-70">
      <div className="container">
        {tipoRamo == 'Odonto-Saude' &&
          <ul className="portfolio-filter mb-45" style={{ listStyleType: 'none' }}>
            {dados.filter((fill) => fill.filter == 'PF').length > 0 &&
              <li className={escolha === "PF" ? "current" : ""} style={{ fontFamily: "Open Sans, sans-serif", border: '1px solid #4180D6' }} >
                Pessoa Física
              </li>
            }
            {dados.filter((fill) => fill.filter == 'PJ').length > 0 &&
              <li className={escolha === "PJ" ? "current" : ""} style={{ fontFamily: "Open Sans, sans-serif", border: '1px solid #4180D6' }}>
                Pessoa Jurídica
              </li>
            }

          </ul>
        }

        <div className="row align-items-center  portfolio-wrap">
          {dados &&
            dados.length > 0 &&
            dados.map((item) => (
              <a href={item.usuario?'https://api.whatsapp.com/send/?phone=55800060706&text=&type=phone_number&app_absent=0':item.link} key={item.id} className={`col-lg-3 col-sm-6 item `} style={{ cursor: "pointer" }}
              >
                <div className="feature-item   " style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: "pointer", height: 300, padding: 10 }} >
                  <div className="" style={{ textAlign: "center", marginTop: 10, height: 50 }}>
                    <p style={{ fontSize: 15, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "250px" }}>
                      <span>{item.title} </span>
                    </p>
                  </div>
                  <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto", height: 130 }}  >
                    <img src={`https://server.corpar.com.br/imagens/${item.image}`} alt="" height={100} />
                  </div>
                  {item.usuario ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: 13, lineHeight: 1.4 }}>

                      <p> É necessário baixar o App, ter usuário, senha e receber as orientações&nbsp;
                        para utilizar este sistema de vendas online.<br/> <a >Entre em contato</a> </p>

                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <a href={item.link} className="btn btn-default" style={{ color: "#fff", backgroundColor: '#4180D6', fontSize: 12, marginTop: 10, width: 100 }}
                      >
                        Acesse Aqui
                      </a>
                    </div>
                  }


                </div>
              </a>
            ))}
          <Modal
            id="modal-center3"
            size="md"
            centered={true}
            isOpen={modalAviso}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Aviso</h5>
              <button
                type="button"
                onClick={() => {
                  setModalAviso(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 30 }}>
              <Row>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    É necessário baixar o App, ter usuário, senha e receber as orientações&nbsp;
                    para utilizar este sistema de vendas online.
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}></span>
                  <span style={{ fontSize: 18 }}>

                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    <br />
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    Precisa de orientação ou não possui senha?
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span
                    style={{
                      color: "rgb(206, 0, 0)",
                      fontFamily: "inherit",
                      fontSize: 18
                    }}
                  >
                    Clique no botão abaixo&nbsp;
                  </span>
                  <span
                    style={{
                      color: "rgb(206, 0, 0)",
                      fontFamily: "inherit",
                      fontSize: 18
                    }}
                  >
                    que entraremos em contato.
                  </span>
                </p>
              </Row>
              <Row style={{ float: "right", marginTop: 20 }}>
                <a
                  href={link}
                  id="link"
                  className="btn btn-success"
                  style={{ float: "right" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Continuar
                </a>
              </Row>
              <Row style={{ float: "left", marginTop: 20 }}>
                <button className="btn btn-warning" style={{ float: "left" }}>
                  Solicitar contato
                </button>
              </Row>
            </div>
          </Modal>
          <Modal
            id="modal-center3"
            size="md"
            centered={true}
            isOpen={modalMaterial}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Material de Venda</h5>
              <button
                type="button"
                onClick={() => {
                  setModalMaterial(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 30, backgroundColor: '#f5f5f5' }}>
              <Info id={id} estado={datas.estado} />
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
}
