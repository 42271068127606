import React from "react";
import { Modal,Row,Col } from "reactstrap";
export const ValidacaoCelular = ({modalCelular,setModalCelular,handleInputChange,setCodigo1,codigo1,setCodigo2,codigo2,setCodigo3,codigo3,setCodigo4,codigo4,handleDisparoCelular,handleValidarCelular,input1Ref,input2Ref,input3Ref,input4Ref,celular,loading,start,time}) => {
    return(
        <Modal isOpen={modalCelular} centered={true}>
        <div className="modal-header" style={{border:'none'}}>
        
          <button
            type="button"
            onClick={() => {
              setModalCelular(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
        <p style={{ color: '#092140',textAlign:'center' }}>Valide seu Celular</p>
          <p style={{ fontSize: 15,textAlign:'center' }}>
            Digite o código enviado para seu WhatsApp ou SMS{" "}<br/>
            {celular}
          </p>
          <Row style={{justifyContent:'center',alignItems:'center'}}>
            <Col lg={2} >
              <input
                className="form-control"
                value={codigo1}
                ref={input1Ref}
                onChange={(e) => {
                  handleInputChange(e);
                  setCodigo1(e.target.value);
                }}
                style={{ textAlign: "center" }}
                maxLength={1}
              />
            </Col>
            <Col lg={2}>
              <input
                className="form-control"
                value={codigo2}
                ref={input2Ref}
                onChange={(e) => {
                  handleInputChange(e);
                  setCodigo2(e.target.value);
                }}
                style={{ textAlign: "center" }}
                maxLength={1}
              />
            </Col>
            <Col lg={2}>
              <input
                className="form-control"
                value={codigo3}
                ref={input3Ref}
                onChange={(e) => {
                  handleInputChange(e);
                  setCodigo3(e.target.value);
                }}
                style={{ textAlign: "center" }}
                maxLength={1}
              />
            </Col>
            <Col lg={2}>
              <input
                className="form-control"
                value={codigo4}
                ref={input4Ref}
                onChange={(e) => {
                  handleInputChange(e);
                  setCodigo4(e.target.value);
                }}  
                style={{ textAlign: "center" }}
                maxLength={1}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer" style={{border:'none',paddingRight:25,marginTop: -9}}>
          <button
            className="btn btn-link"
            disabled={loading ? loading : start ? true : false}
            onClick={() => {
              handleDisparoCelular();
            }}
            style={{color:'red',fontWeight:300}}
          >
            {loading
              ? "Reenviar código"
              : start
                ? `00:${time < 10 ? "0" + time : time}`
                : `Reenviar código`}
          </button>
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={() => {
              handleValidarCelular();
            }} >
            Avançar
          </button>
        </div>
      </Modal>
    )
}