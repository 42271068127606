import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { baseUrlApi } from "../../api/baseUrl";
export const GestorModal = ({ dados }) => {
    function executarRotinasAleatorias(rotinas) {
        // Obter a data atual no formato YYYY-MM-DD
        const hoje = new Date().toISOString().split('T')[0];

        // Variável para controlar se alguma rotina já foi executada
        let rotinaExecutada = false;

        // Itera sobre cada rotina e aplica a lógica de execução
        rotinas?.forEach((item) => {
            if (item?.visualizarsempre == 'SIM') {
                executarFuncao(item);
            } else {
                if (rotinaExecutada) return; // Se uma rotina já foi executada, as outras são ignoradas

                let nome = item.nome;
                let frequencia = item.frequencia;

                // Chaves específicas para a rotina no localStorage
                const chaveData = `ultimaData_${nome}`;
                const chaveAcessos = `numeroAcessos_${nome}`;
                const chaveExecucoes = `execucoesHoje_${nome}`;

                // Recupera a última data, o número de acessos e execuções da rotina no localStorage
                const ultimaData = localStorage.getItem(chaveData);
                let acessos = parseInt(localStorage.getItem(chaveAcessos) || '0', 10);
                let execucoesHoje = parseInt(localStorage.getItem(chaveExecucoes) || '0', 10);

                // Se a data mudou, zera o contador de acessos e execuções
                if (ultimaData !== hoje) {
                    acessos = 0;
                    execucoesHoje = 0; // Zera as execuções diárias
                    localStorage.setItem(chaveData, hoje); // Atualiza a data para hoje
                }

                // Incrementa o número de acessos
                acessos += 1;
                localStorage.setItem(chaveAcessos, acessos); // Salva o número atualizado de acessos no localStorage

                // Verifica se a função já foi executada o número de vezes equivalente à frequência
                if (execucoesHoje >= frequencia) {
                    //  console.log(`A rotina "${nome}" já foi executada ${frequencia} vez(es) hoje. Aguardando o próximo dia.`);

                    return; // Se já atingiu o limite de execuções, não executa mais
                }

                // Se for a primeira execução do dia, executa imediatamente
                if (execucoesHoje === 0) {
                    executarFuncao(nome);
                    execucoesHoje += 1;
                    localStorage.setItem(chaveExecucoes, execucoesHoje); // Atualiza o contador de execuções
                    rotinaExecutada = true; // Marca que uma rotina foi executada
                }
                // Para as execuções subsequentes, usa a lógica aleatória
                else if (frequencia > 1) {
                    // Gera um número aleatório entre 1 e a frequência especificada
                    const numeroAleatorio = Math.floor(Math.random() * frequencia) + 1;
                    //  console.log({ numeroAleatorio, frequencia, execucoesHoje });

                    // Executa a função se o número de acessos coincidir com o número aleatório
                    if (frequencia === numeroAleatorio) {
                        executarFuncao(item);
                        execucoesHoje += 1; // Incrementa o número de execuções
                        localStorage.setItem(chaveExecucoes, execucoesHoje); // Atualiza o contador de execuções
                        rotinaExecutada = true; // Marca que uma rotina foi executada
                    }
                }
            }


        });
    }

    // Função a ser executada
    function executarFuncao(item) {
        // console.log({item})
        if (item.imagem || item.video || item.descricao || item.url) {
            setImagem(item.imagem)
            setVideo(item.video)
            setUrl(item.url)
            setDescricao(item.descricao)
            setModal(true)
        }


        // console.log("Função executada!");
    }
    const [modal, setModal] = useState(false)
    useEffect(() => {

        executarRotinasAleatorias(dados)

    }, [dados])
    const modalRef = useRef();
    const [imagem, setImagem] = useState("")
    const [video, setVideo] = useState("")
    const [descricao, setDescricao] = useState("")
    const [url, setUrl] = useState("")
    useEffect(() => {
        // Função para detectar clique fora do modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModal(false); // Fecha o modal se o clique foi fora dele
            }
        };

        // Adiciona o evento de clique ao documento
        document.addEventListener('mousedown', handleClickOutside);

        // Remove o evento quando o componente é desmontado
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setModal]);

    if (!modal) return null;
    return (
        <Modal
            id="modal-center3"
            size="md"
            isOpen={modal}
            centered={true}
            style={{ maxWidth: 600 }}
        >

            <div className="modal-body" ref={modalRef} >
                <Row >
                    <div className="col-12" >
                        <button type="button"
                            onClick={() => {
                                setModal(false);
                            }} className="close" data-dismiss="modal" aria-label="Close" style={{ float: 'right', marginRight: 10 }} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </Row>
                {(url || video) &&
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:10}} >
                        <iframe width={url ? '100%' : '50%'} style={{borderRadius:10}} height={'300px'} src={url || `https://server.corpar.com.br/video/` + video} title="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                }


                {imagem &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                        <img src={`https://server.corpar.com.br/imagem/` + imagem} width={'100%'} />
                    </div>
                }
                {descricao ?
                    <p style={{ textAlign: 'justify', padding: '10px', lineHeight: 1 }} dangerouslySetInnerHTML={{ __html: descricao }} >
                    </p>
                    : ""}



            </div>

        </Modal >
    )
}