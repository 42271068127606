import React, { useEffect, useState,useRef } from "react";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import Info from "./info";
export default function MainSection({ datas }) {
  const [dados, setDados] = useState([]);
  const [item2, setItem] = useState("");
  const handleFiltro = (val) => {
    let fisica = datas.valores
    setDados(fisica);
  };
  const handleDados = () => {
    handleFiltro();
  };
  useEffect(() => {

    handleDados();
  }, [datas]);
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value}`).length;
    if (getItems > 0) {
      if (item2 !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  const sliderRef = useRef(null);
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: dados.length<5?dados.length:5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  return (
    <section className=" ">

      <div className="" style={{ marginTop: 8, marginBottom: 12, marginTop: 20, display: 'flex' }} >
          <div className="" style={{ margin: 'auto', width: '10%' }}>
            <MdArrowBackIos onClick={prevHandler} color="#ccc" size={20} />
          </div>
      
          <div className=" " style={{ width: '100%' }} >
            <div className="logo-carousel-wrap " >
              <SliderCom settings={settings} selector={sliderRef}>
                {dados &&
                  dados.map((item) => (
                    <>
                      <div className="logo-item">
                        <a href={`/empresas/${item.id}`}>
                          <img src={`https://server.corpar.com.br/imagens/${item.operadora?.imagem}`} width={200}  />
                        </a>
                      </div>
                    </>
                  ))}
              </SliderCom>
            </div>

          </div>
          <div className="" style={{ margin: 'auto', width: '10%' }}>
            <MdArrowForwardIos onClick={nextHandler} color="#ccc" size={20} />
          </div>
        </div>

    </section>
  );
}
