import React from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function ServiceOne() {
  const { estado, plano } = useParams();
  return (
    <Layouts
      pageTitle={`Links de Vendas Online`}
      breadcrumbs={[
        { name: "Home", path: "../" },
        { name: "Links de Vendas Online", path: "/vendaOline" },
      ]}
    >
      <Dados plano={plano} estado={estado} />
    </Layouts>
  );
}
