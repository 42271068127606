import React from "react";
import { Col, Modal } from "reactstrap";
export const Visualizar = ({ calculo, modalVisualizarOperadora, setModalVisualizarOperadora, calculoDetalhe, handleOperadora, handleVisualizarCotacoes, setModalFiltro, handleCarregamento, ramo }) => {

  return (
    <Modal id="modal-center3" size="xl" isOpen={modalVisualizarOperadora} centered={true}>

      <div className="modal-body">
        <div style={{ marginTop: 10,marginRight:10 }}>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"
            onClick={() => {
              setModalVisualizarOperadora(false);
            }} >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style={{ marginTop: 10 }}>
          <Col lg={12} style={{ marginBottom: 50 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                setModalFiltro(true);
              }}
              style={{ float: "right", backgroundColor: '#4180D6' }}
            >
              Filtrar
            </button>
            <button
              className="btn btn-primary"
              onClick={handleVisualizarCotacoes}
              style={{ float: "right", marginRight: 10, backgroundColor: '#4180D6' }}
            >
              Visualizar Cotações
            </button>
          </Col>
        </div>

        <table className="table" >
          <thead>
            <tr>
              <th>
                <p style={{ fontWeight: 100 }}>Cálculo Nº {calculoDetalhe?.id}</p>
                <p style={{ fontWeight: 100 }}>Total de vidas {calculoDetalhe?.vidas}</p>
                <p style={{ fontWeight: 100 }}>
                  {calculoDetalhe?.nomeempresa
                    ? `Empresa ${calculoDetalhe?.nomeempresa}`
                    : `Nome ${calculoDetalhe?.nome}`}
                </p>
              </th>
              <th style={{ textAlign: "right" }}>
                <p style={{ fontWeight: 100 }}>
                  {calculoDetalhe?.valorMinimo?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  })}
                </p>
                <p style={{ fontWeight: 100 }}>
                  {calculoDetalhe?.valorDoMeio?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  })}
                </p>
                <p style={{ fontWeight: 100 }}>
                  {calculoDetalhe?.valorMaximo?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  })}
                </p>
              </th>
              <th>
                <p style={{ fontWeight: 100, fontSize: 13 }}>Menor Preço ({calculoDetalhe?.operadoraValorMinimo})</p>
                <p style={{ fontWeight: 100, fontSize: 13 }}>
                  Preço Intermediário (
                  {calculoDetalhe?.operadoraValoIntermediario})
                </p>
                <p style={{ fontWeight: 100, fontSize: 13 }}>Maior Preço ({calculoDetalhe?.operadoraValorMaximo})</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {calculo.length > 0 &&
              calculo?.map((item) => (
                <tr key={item.id} style={{ textAlign: "center" }}>
                  <td style={{ width: 500 }}>
                    {" "}
                    <img
                      src={`https://server.corpar.com.br/imagens/${item.imagem}`}
                      alt=""
                      width={'20%'}

                    />
                    <br />
                    <p style={{ textAlign: "center", fontSize: 14 }}>
                      {item.nome}
                    </p>
                  </td>
                  <td>
                    <a
                      className="btn btn-link"
                      style={{ fontWeight: 100, color: '#000' }}
                      href={`/operadora/${item.nome.replace(/ /g, "-")}`}
                    >
                      Ver Material de Vendas
                    </a>
                  </td>
                  <td>
                    {item.status == 3 ? (
                      <>
                        <button className="btn btn-danger">
                          Em atualização
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary"
                          style={{ backgroundColor: '#000' }}
                          onClick={() => {
                            handleCarregamento(true);
                            handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) });
                          }}
                        >
                          Abrir {item.tabelas.length} calculos
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
