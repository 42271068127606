import React, { useEffect, useRef, useState } from "react";
import { Col, Input, Label, Modal, Row, UncontrolledAlert } from "reactstrap";
// Formik Validation

// action
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench, BiHide } from "react-icons/bi";
//redux
import InputMask from 'react-input-mask';

import { useDispatch } from "react-redux";
import { Api } from "../../../../api/apiLogin";
import { Api as ApiSite } from "../../../../api";
import { ValidacaoEmail } from "../Validacao/email";
import { ValidacaoCelular } from "../Validacao/celular";
export const Cadastro = ({ setModalCadastro, modalCadastro, setModalLogin }) => {
  // const historia = useHistory();

  const [nomeFantasia, setNomeFantasia] = useState("");
  const [data, setData] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [celular1, setCelular1] = useState("");
  const [celular2, setCelular2] = useState("");
  const [validaEmail, setValidaEmail] = useState(false);
  const [validarCelular, setValidaCelular] = useState(false);
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [info, setInfo] = useState([]);
  const [proximo, setProximo] = useState(1);
  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const [codigo5, setCodigo5] = useState("");
  const [codigo6, setCodigo6] = useState("");
  const [codigo7, setCodigo7] = useState("");
  const [codigo8, setCodigo8] = useState("");
  const [nome, setNome] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [id, setid] = useState();
  const [modalCelular, setModalCelular] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const [complemento, setComplemento] = useState("");
  const [avanca, setAvancar] = useState(false);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.charAt(9))) {
      return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  }
  const validatePassword = () => {
    const regex = /^(?=.*[A-Za-z])[0-9A-Za-z]{8,}$/;
    setIsValid(!regex.test(password));
  };
  function handleInputChange(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input1Ref.current) {
        input2Ref.current.focus();
      } else if (e.target === input2Ref.current) {
        input3Ref.current.focus();
      } else if (e.target === input3Ref.current) {
        input4Ref.current.focus();
      } else if (e.target === input4Ref.current) {
        // aqui você pode adicionar o código para passar para o próximo campo de texto
      }
    }
  }
  function handleInputChangeEmail(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input5Ref.current) {
        input6Ref.current.focus();
      } else if (e.target === input6Ref.current) {
        input7Ref.current.focus();
      } else if (e.target === input7Ref.current) {
        input8Ref.current.focus();
      } else if (e.target === input8Ref.current) {
        // aqui você pode adicionar o código para passar para o próximo campo de texto
      }
    }
  }

  const handleValidarCelular = async () => {
    setLoading(true);
    let valida = await Api.validarCelular({
      id: id,
      codigo: `${codigo1}${codigo2}${codigo3}${codigo4}`,
      celular: celular
    });
    if (valida.error == false) {
      setCodigo1("");
      setCodigo2("");
      setCodigo3("");
      setCodigo4("");
      setModalCelular(false);
      setValidaCelular(true);
    } else {
      alert(valida.msg);
    }
    setLoading(false);
  };
  const handleValidarEmail = async () => {
    setLoading(true);

    let valida = await Api.validarEmail({
      id: id,
      codigo: `${codigo5}${codigo6}${codigo7}${codigo8}`,
      email: email
    });
    if (valida.error == false) {
      setCodigo5("");
      setCodigo6("");
      setCodigo7("");
      setCodigo8("");
      setModalEmail(false);
      setValidaEmail(true);
    } else {
      alert(valida.msg);
    }
    setLoading(false);
  };
  const handleDisparoEmail = async () => {
    setLoading(true);
    if (!start) {
      setTime(59);
      localStorage.setItem("time", JSON.stringify(0));
      let valida = await Api.disparoEmail({ email: email, id: id });
      alert("Email enviado!");
      setStart(true);
    }

    setLoading(false);
  };
  const handleDisparoCelular = async () => {
    setLoading(true);
    if (!start) {
      setTime(59);
      localStorage.setItem("time", JSON.stringify(0));
      let valida = await Api.disparoCelular({ celular: celular, id: id });
      alert("Mensagem enviada!");
      setStart(true);
    }
    setLoading(false);
  };

  const [validaCep, setValidaCep] = useState(false)
  const handleCEP = async (cep) => {
    let string = cep.replace(/\D/g, "");
    const formattedCep = formatCep(string);
    setCep(formattedCep);
    if (string) {
      let dados
      setInfo({})
      await fetch(`https://viacep.com.br/ws/${string}/json`)
        .then((res) => res.json())
        .then((data) => {
          setMsg()
          dados = data
          setInfo(data);
          setValidaCep(false)
        });

      if (!dados?.cep) {
        setMsg('CEP inválido.')
        setValidaCep(true)
      }
    }

  };
  useEffect(() => { }, [dispatch]);
  useEffect(() => {
    const validarEmail = () => {
      // Expressão regular para validar um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const formatarCelular = () => {
      // Adiciona o prefixo "55" e formata o número do celular
      if (celular?.startsWith("55")) {
        return (
          "55" +
          celular
            .substring(2)
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2")
        );
      } else {
        if (celular) {
          return celular
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2");
        } else {
          return "";
        }
      }
    };
    const formatarCelular1 = () => {
      // Adiciona o prefixo "55" e formata o número do celular
      if (celular1?.startsWith("55")) {
        return (
          "55" +
          celular1
            .substring(2)
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2")
        );
      } else {
        if (celular1) {
          return celular1
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2");
        } else {
          return "";
        }
      }
    };
    const formatarCelular2 = () => {
      // Adiciona o prefixo "55" e formata o número do celular
      if (celular2?.startsWith("55")) {
        return (
          "55" +
          celular2
            .substring(2)
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2")
        );
      } else {
        if (celular2) {
          return celular2
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2");
        } else {
          return "";
        }
      }
    };

    const formatarCPF = () => {
      // Adiciona pontos e traços ao CPF
      const value = cpf.replace(/\D/g, "");
      if (value.length <= 11) {
        // CPF
        return cpf
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      } else {
        // CNPJ
        return cpf.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          "$1.$2.$3/$4-$5"
        );
      }
    };

    if (validarEmail()) {
      // E-mail válido, pode ser utilizado
      //  console.log("E-mail válido:", email);
    }
    setCelular1(formatarCelular1());
    setCelular2(formatarCelular2());
    setCelular(formatarCelular());
    setCpf(formatarCPF());
  }, [email, celular, celular1, celular2, cpf]);
  useEffect(() => {
    if (avanca) {
      if (!validaEmail) {
        setModalEmail(true);
      }
      if (!validarCelular) {
        setModalCelular(true);
      }
      if (validarCelular && validaEmail) {
        setProximo(proximo + 1);
      }
    }
  }, [validaEmail, validarCelular]);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCelularChange = (event) => {
    const inputCelular = event.target.value;
    // Limita o número de caracteres do celular em 15
    if (inputCelular.length <= 15) {
      setCelular(inputCelular);
    }
  };
  const handleCelular1Change = (event) => {
    const inputCelular = event.target.value;
    // Limita o número de caracteres do celular em 15
    if (inputCelular.length <= 15) {
      setCelular1(inputCelular);
    }
  };
  const handleCelular2Change = (event) => {
    const inputCelular = event.target.value;
    // Limita o número de caracteres do celular em 15
    if (inputCelular.length <= 15) {
      setCelular2(inputCelular);
    }
  };

  const handleCpfChange = (event) => {
    const inputCPF = event.target.value;
    // Limita o número de caracteres do CPF em 14
    if (inputCPF.length <= 14) {
      setCpf(inputCPF);
    }
  };
  document.title = "Corretor Parceiro";

  const handleProximo = async () => {
    let status = true;
    if (password) {
      status = false;
      if (password == passwordConfirm && !isValid) {
        status = true;
      }
    }

    if (status) {
      setLoading(true);
      let cadastro = await Api.cadastro({
        id: id,
        nome: proximo == 2 ? nome : "",
        cpf: proximo == 1 ? value : "",
        celular: proximo == 1 ? celular : "",
        email: proximo == 1 ? email : "",
        data: proximo == 2 ? data : "",
        nomeFantasia: proximo == 2 ? nomeFantasia : "",

        bairro: proximo == 3 ? (info?.bairro ? info?.bairro : "") : "",
        complemento1: proximo == 3 ? complemento : "",
        ddd: proximo == 3 ? (info?.ddd ? info.ddd : "") : "",
        ibge: proximo == 3 ? (info?.ibge ? info.ibge : "") : "",
        rua: proximo == 3 ? (info?.logradouro ? info.logradouro : "") : "",
        cidade: proximo == 3 ? (info?.localidade ? info.localidade : "") : "",
        siafi: proximo == 3 ? (info?.siafi ? info.siafi : "") : "",
        estado: proximo == 3 ? (info?.uf ? info.uf : "") : "",
        cep: proximo == 3 ? cep : "",
        numero: proximo == 3 ? numero : "",

        password: password
      });

      if (cadastro.logar) {
        localStorage.setItem("authUser", JSON.stringify(cadastro));
        window.location.href = `/ferramentas-de-venda`;
        //    historia.push("/dashboard-cotacoes");
      }
      if (cadastro.error) {
        setMsg(cadastro.msg);
      } else {
        setMsg('')
        // console.log(cadastro, cadastro.validacelular)
        setAvancar(true);
        if (cadastro.id) {
          setid(cadastro.id);
        }
        if (!cadastro.validaemail) {
          setValidaEmail(false);
          setCodigo5("");
          setCodigo6("");
          setCodigo7("");
          setCodigo8("");
          setEmail(cadastro.email);
          setModalEmail(true);
        } else {
          setValidaEmail(true);
        }
        if (!cadastro.validacelular) {
          setCodigo1("");
          setCodigo2("");
          setCodigo3("");
          setCodigo4("");
          setCelular(cadastro.celular);
          setModalCelular(true);
          setValidaCelular(false);
        } else {
          setValidaCelular(true);
        }

        if (validaEmail && validarCelular) {
          setProximo(proximo + 1);
        }
      }
      setLoading(false);
    } else {

      alert(!isValid ? "As senhas precisam ser iguais!" : 'A senha deve conter 8 digitos (letras e números)');
    }
  };
  const [time, setTime] = useState(30);
  const [start, setStart] = useState(false);
  const tempoPrincipal = localStorage.getItem("time");
  const handleTime = () => {
    let tempo1 = 1;
    let timer = setInterval(() => {
      let tempo = 0;
      if (tempoPrincipal > 0) {
        setTime(tempoPrincipal);
        tempo = tempoPrincipal - tempo1;
        tempo1 = tempo1 + 1;
        setTime(tempo);
        localStorage.setItem("time", JSON.stringify(tempo));
      } else {
        tempo = time - tempo1;
        tempo1 = tempo1 + 1;
        setTime(tempo);
        localStorage.setItem("time", JSON.stringify(tempo));
      }
      if (tempo == 0) {
        setStart(false);
        clearInterval(timer);
      }
    }, 1000);
  };
  useEffect(() => {
    if (start) {
      handleTime();
    }
  }, [start]);


  useEffect(() => {
    setTitle(
      proximo == 1
        ? "Crie seu Usuário"
        : proximo == 2
          ? "Complete seu Cadastro"
          : proximo == 3
            ? "Preencha seu Endereço"
            : proximo == 4 ?
              "Crie sua senha"
              :
              ""
    );

  }, [proximo]);
  const [valiCPF, SetValiCPF] = useState(false);
  const [title, setTitle] = useState("CADASTRE SEU USUÁRIO");
  const [value, setValue] = useState("");

  const handleInputChangeCPF = (event) => {
    const { value } = event.target;
    let formattedValue = value.replace(/\D/g, ""); // Remove tudo que não é dígito
    formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2");
    formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2");
    formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    let valida = validarCPF(formattedValue)
    SetValiCPF(!valida)
    setValue(formattedValue);
  };
  const [handleHide, setHandleHide] = useState([]);
  const handleShowHide = async (type, status, index) => {
    if (status) {
      document.querySelector(`input[id="password${type}"]`).type = 'text'
    } else {
      document.querySelector(`input[id="password${type}"]`).type = 'password'
    }
    let valores = [...handleHide]
    valores[index] = !valores[index]
    setHandleHide(valores)
  }

  const [documentValue, setDocumentValue] = useState('');
  const [mask, setMask] = useState('999.999.999-99'); // Máscara vazia inicialmente
  const formatValue = (value) => {
    if (!value) return '';

    const isCnpj = value.length > 11;
    if (isCnpj) {
      return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    } else {
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
  };

  const handleDocumentChange = async (event) => {
    const inputValue = event.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    const formattedValue = formatValue(inputValue);
    setValue(inputValue)
    setDocumentValue(formattedValue);

    const isCnpj = inputValue.length > 11;

    if (isCnpj) {
      setMask('99.999.999/9999-99');
      let response = await ApiSite.DadosBuscarCNPJ(inputValue)
      if (response?.dados?.razao) {
        setNome(response?.dados?.razao)
      } else {
        setNome('')
      }

    } else {
      setMask('999.999.999-99');
    }
  };
  const [dateValue, setDateValue] = useState('');
  const dateFormat = 'DD/MM/YYYY';

  const handleDateChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    let data = event.target.value.split('/')
    setData(event.target.value ? `${data[2]}-${data[1]}-${data[0]}` : '')
    const formattedDate = formatDate(inputValue);

    setDateValue(formattedDate);
  };

  const formatDate = (value) => {
    const parts = [
      value.slice(0, 2),
      value.slice(2, 4),
      value.slice(4, 8),
    ];

    return parts.filter(Boolean).join('/');
  };

  const formatCep = (value) => {
    const parts = [
      value.slice(0, 5),
      value.slice(5, 8),
    ];

    return parts.filter(Boolean).join('-');
  };

  return (
    <Modal id="modal-center3" size="lg" centered={true} isOpen={modalCadastro}>
      <div className="modal-header" style={{ border: 'none' }} >
        <button
          type="button"
          onClick={() => {
            setProximo(1)
            setModalCadastro(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>

        <Row className="g-0">
          <Col lg={12} md={12} className="col-xxl-12">
            <div className="auth-full-page-content d-flex p-sm-2 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="auth-content my-auto">
                    <div className="text-center">

                      <p style={{ color: '#092140', fontSize: 20 }}>{title}</p>
                      <p style={{ color: 'red', fontSize: 15 }}>{msg ? '*' + msg : ''}</p>
                      <p>{proximo == 4 && "8 digitos (letras e números)"}</p>
                    </div>

                    {resposta && (
                      <UncontrolledAlert
                        toggle={() => {
                          setResposta(false);
                        }}
                        to
                        color={error ? "danger" : "success"}
                      >
                        {error ? (
                          <>
                            <h5 className="text-danger">
                              <i className="mdi mdi-block-helper    text-danger"></i>{" "}
                              Error
                            </h5>
                          </>
                        ) : (
                          <>
                            <h5 className="text-success">
                              <i className="mdi mdi-check-all text-success"></i>{" "}
                              Sucesso
                            </h5>
                          </>
                        )}
                        {msg}
                      </UncontrolledAlert>
                    )}

                    {proximo == 1 && (
                      <Row>
                        <Col lg={6}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label"  > *CPF ou CNPJ </Label>
                              <input type="text" id="document" className="form-control" value={documentValue} onChange={handleDocumentChange} maxLength="18" />
                              {/* <input id="cpfCnpjInput" maxLength={14} type="text" value={value} onChange={handleInputChangeCPF} className="form-control" /> */}
                              {valiCPF && (
                                <p style={{ color: "red", fontSize: 12 }}>
                                  CPF invalido!
                                </p>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <div className="mb-2">
                              <Label htmlFor="example-text-input" className="form-Label"  >
                                *Celular Principal
                              </Label>
                              <Input name="celular" maxLength={15} autocomplete="new-password" value={celular} onChange={handleCelularChange} className="form-control" type="tel" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div>
                            <div className="mb-2">
                              <Label htmlFor="example-text-input" className="form-Label" >
                                *E-mail
                              </Label>
                              <Input name="email" autocomplete="new-password" value={email} onChange={handleEmailChange} className="form-control" type="email" style={{ textTransform: 'none' }} />
                            </div>
                          </div>
                        </Col>

                      </Row>
                    )}
                    {proximo == 2 &&
                      <Row>
                        <Col lg={12}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label" >
                                {value.length > 11 ? "*Razão Social" : "*Nome Completo"}
                              </Label>
                              <Input name="nome" value={nome} onChange={(e) => { setNome(e.target.value); }} className="form-control" type="text" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label" >{value.length > 11 ? "Nome do Responsável" : "Nome Fantasia"} </Label>
                              <Input name="nomeFantasia" value={nomeFantasia} onChange={(e) => { setNomeFantasia(e.target.value); }} className="form-control" type="text" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label" >
                                *Data de Nascimento
                              </Label>
                              <InputMask className="form-control" mask="99/99/9999" maskPlaceholder={null} value={dateValue}
                                onChange={handleDateChange}
                                id="date"
                              />
                              {/* <input type="text" id="date" value={dateValue} onChange={handleDateChange} maxLength="10" placeholder="DD/MM/AAAA" /> */}
                              {/* <Input name="data" value={data} onChange={(e) => { setData(e.target.value); }} className="form-control" type="date" /> */}
                            </div>
                          </div>
                        </Col>

                      </Row>
                    }

                    {proximo == 3 && (
                      <Row>
                        <Col lg={3}>
                          <div>
                            <div className="mb-1">
                              <Label
                                htmlFor="example-text-input"
                                className="form-Label"
                              >
                                *CEP
                              </Label>
                              <InputMask mask="99999-999" className="form-control" maskPlaceholder={null} value={cep} onChange={(e) => { setCep(e.target.value); }} onBlur={(e) => { handleCEP(e.target.value); }} id="cep" />

                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label" >
                                Estado
                              </Label>
                              <Input name="estado" autocomplete="new-password" disabled value={info?.uf ? info.uf : info.estado} className="form-control" type="text" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <div className="mb-1" >
                              <Label htmlFor="example-text-input" className="form-Label" >
                                Cidade
                              </Label>
                              <Input name="cidade" autocomplete="new-password" disabled value={info?.localidade ? info.localidade : info.cidade} className="form-control" type="text" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label" >
                                Rua
                              </Label>
                              <Input name="rua" autocomplete="new-password" disabled value={info?.logradouro ? info.logradouro : info.rua} className="form-control" type="text" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-1">

                            <Label
                              htmlFor="example-text-input"
                              className="form-Label"
                            >
                              Número
                            </Label>
                            <input name="numero" value={numero} onChange={(e) => { setNumero(e.target.value); }} className="form-control" type="number" />

                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <Label htmlFor="example-text-input" className="form-Label" >
                              Bairro
                            </Label>
                            <input name="bairro" disabled value={info?.bairro ? info.bairro : info.bairro} className="form-control" type="text" />

                          </div>
                        </Col>
                        <Col lg={12}>
                          <div>
                            <div className="mb-1">
                              <Label htmlFor="example-text-input" className="form-Label" >
                                Complemento
                              </Label>
                              <Input name="complemento" autocomplete="new-password" onChange={(e) => { setComplemento(e.target.value); }} value={complemento} className="form-control" type="text" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {(proximo == 4 || proximo > 4) && (
                      <Row>
                        <Col lg={6}>
                          <div className="row"  >
                            <div className="col-12" >
                              <Label htmlFor="example-text-input" className="form-Label">
                                *Senha
                              </Label>
                              <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <div style={{ flex: 2 }}>
                                  <Input id="password0" name="password" autocomplete="new-password" onBlur={() => { validatePassword(); }} value={password} onChange={handlePasswordChange} className="form-control" type="password"
                                  />
                                </div>
                                <div style={{ border: '2px solid #cfdbf1', padding: 3, borderRadius: '5px' }} >
                                  {handleHide[0] ? <BiShow size={20} onClick={() => { handleShowHide('0', false, 0) }} /> : <BiHide size={20} onClick={() => { handleShowHide('0', true, 0) }} />}
                                </div>

                              </div>

                            </div>

                          </div>


                        </Col>
                        <Col lg={6}>


                          <Label htmlFor="example-text-input" className="form-Label">
                            *Confirmar Senha
                          </Label>
                          <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <div style={{ flex: 2 }}>
                              <Input id="password1" name="passwordConfirm" autocomplete="new-password" value={passwordConfirm} onChange={(e) => { setPasswordConfirm(e.target.value); }} className="form-control" type="password" />
                            </div>

                            <div style={{ border: '2px solid #cfdbf1', padding: 3, borderRadius: '5px' }} >
                              {handleHide[1] ? <BiShow size={20} onClick={() => { handleShowHide('1', false, 1) }} /> : <BiHide size={20} onClick={() => { handleShowHide('1', true, 1) }} />}
                            </div>

                          </div>






                        </Col>



                      </Row>
                    )}


                    {proximo == 1 && (
                      <Col lg={12} style={{ marginLeft: 15, marginTop: 15 }} >
                        <button
                          className="btn btn-primary"
                          type="button"
                          disabled={loading}
                          onClick={() => {
                            handleProximo();
                          }}
                          style={{ float: "right" }}
                        >
                          Avançar
                        </button>
                      </Col>
                    )}

                    {proximo == 2 && (
                      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setProximo(proximo > 1 ? proximo - 1 : proximo);
                            }}
                            style={{ float: "left" }}
                          >
                            Voltar
                          </button>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              handleProximo();
                            }}
                            style={{ float: "right" }}
                          >
                            Avançar
                          </button>
                        </div>
                      </div>
                    )}
                    {proximo == 3 && (
                      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                        <div >
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setAvancar(false);
                              setProximo(proximo > 1 ? proximo - 1 : proximo);
                            }}
                            style={{ float: "left" }}
                          >
                            Voltar
                          </button>
                        </div>
                        <div >
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={loading ? loading : validaCep}
                            onClick={() => {
                              handleProximo();
                            }}
                          >
                            Avançar
                          </button>
                        </div>
                      </div>
                    )}
                    {(proximo == 4 || proximo > 4) && (
                      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                        <div >
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setAvancar(false);
                              setProximo(proximo > 1 ? proximo - 1 : proximo);
                            }}
                            style={{ float: "left" }}
                          >
                            Voltar
                          </button>
                        </div>
                        <div >
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              handleProximo();
                            }}
                          >
                            Avançar
                          </button>
                        </div>
                      </div>
                    )}

                    {proximo == 1 &&
                      <Row>
                        <div className="" style={{ width: '100%', textAlign: 'center' }}>
                          <p className="mb-0" style={{ textAlign: 'center' }}>
                            Ao se cadastrar, você concorda com os
                            <a className="text" style={{ marginLeft: 2, marginRight: 2,color:'red' }} href="/termodeuso"
                              target="_blank"  >
                              Termos de Uso
                            </a>
                            e
                            <a className="text" style={{ marginLeft: 2, marginRight: 2,color:'red' }}  href="/politicaPrivacidade" target="_blank" >
                              Política de Privacidade
                            </a>
                          </p>
                        </div>


                        <div className=" text-center" style={{ width: '100%', textAlign: 'center' }}>
                          <p className=" mb-0">
                            Já possui cadastro?{" "}
                            <a className="text fw-semibold" style={{color:'red'}} onClick={() => {
                              setModalCadastro(false)
                              setModalLogin(true)
                            }}>
                              {" "}
                              Clique Aqui{" "}
                            </a>{" "}
                          </p>
                        </div>
                      </Row>
                    }

                  </div>

                </div>
              </div>
            </div>
          </Col>
        </Row>

        <ValidacaoEmail setModalEmail={setModalEmail} email={email} handleDisparoEmail={handleDisparoEmail} handleInputChangeEmail={handleInputChangeEmail} handleValidarEmail={handleValidarEmail} loading={loading} modalEmail={modalEmail} setCodigo5={setCodigo5} setCodigo6={setCodigo6} setCodigo7={setCodigo7} setCodigo8={setCodigo8} start={start} time={time} codigo5={codigo5} codigo6={codigo6} codigo7={codigo7} codigo8={codigo8} input5Ref={input5Ref} input6Ref={input6Ref} input7Ref={input7Ref} input8Ref={input8Ref} />

        <ValidacaoCelular codigo1={codigo1} codigo2={codigo2} codigo3={codigo3} codigo4={codigo4} handleDisparoCelular={handleDisparoCelular} handleInputChange={handleInputChange} handleValidarCelular={handleValidarCelular} modalCelular={modalCelular} setCodigo1={setCodigo1} setCodigo2={setCodigo2} setCodigo3={setCodigo3} setCodigo4={setCodigo4} setModalCelular={setModalCelular} input1Ref={input1Ref} input2Ref={input2Ref} input3Ref={input3Ref} input4Ref={input4Ref} celular={celular} start={start} loading={loading} time={time} />


      </div>
    </Modal>
  );
};
