import useToggle from "../../../../hooks/useToggle";
import Drawer from "../menu";
import React, { useEffect, useState } from "react";
import boneco from "../../../../assets/images/icones/boneco.png";
import { Link } from "react-router-dom";
import { Login } from "../Login";
import { MeusDados } from "../MeusDados";
import { googleLogout } from '@react-oauth/google';
import logoAqui from "../../../../assets/images/sualogoaqui.png";
import { GoGear } from "react-icons/go";
import { RxExit } from "react-icons/rx";
import { FiMenu } from "react-icons/fi";
import bonecoEditar from "../../../../assets/images/icones/editar.png";
import { Api } from "../../../../api";
import { baseUrlApi, baseUrlApp } from "../../../../api/baseUrl";
export default function MobileHeaderCom({ className }) {
  const [drawer, setValue] = useToggle(false);
  const [modalLogin, setModalLogin] = useState(false)
  const [modalDados, setModalDados] = useState(false)
  const [logo, setLogo] = useState('')

  const getAuthUser = localStorage.getItem("authUser");
  
  const obj = getAuthUser == "undefined" || getAuthUser == null ||
    getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
  }, [obj])
  const handleLink = () => {
    window.open(`${baseUrlApp}/login/${obj.id}/${obj.token}`, "_blank");
    //console.log('handleLink',`${baseUrlApp}/login/${obj.id}/${obj.token}`)
  }
  const [comunicados, setComunicados] = useState('')
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const handleDados = async () => {
    let response = await Api.blogs();
    let list = response?.list?.list
    setComunicados(list?.length)
  };
  const handleDominio = async () => {
    let response = await Api.dominios({dominio:window.location.hostname});
   
    let list = response?.list[0]
    if(list?.imagem){
      setLogo(list?.imagem)
    }else{
      window.location.href='/unauthorized'
    }
  };
  useEffect(() => {
    handleDados()
    handleDominio()
  }, [])
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawer]);
  function capitalizeFirstLetter(string) {
    if (string.length === 0) return string;
    let PrimeiraLetra = string[0].toUpperCase()
    let OutrasLetra = string.slice(1).toLowerCase()

    return PrimeiraLetra + OutrasLetra
  }
  return (
    <>
      <Drawer drawer={drawer} action={setValue.toggle} />
      <div className={`mobile-header d-block `} style={{ padding: 0 }} >
        <div className="d-flex justify-content-between" style={{ alignItems: "center", height: "100%" }}>
          <div className="button-area" style={{ display: 'flex', justifyContent: isDesktop ? "" : "space-between", alignItems: 'center', width: '100%' }} >
            <div style={{display:'flex'}}>
              <a href="/" className="logo-area" style={{ display: 'flex', justifyContent: 'space-between',marginRight:10 }}>
                <FiMenu size={30} color={'#0639A4'} style={{ cursor: 'pointer' }} onClick={setValue.toggle} />
              </a>
              <a href="/" className="logo-area" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <img src={`${baseUrlApi}/imagens/${logo}`}  width={120} />
              </a>
            </div>

            {obj.id && <img src={obj.foto ? `${baseUrlApi}/imagens/${obj.foto}` : logoAqui} width={"100px"}  style={{ width: "100px", marginRight: 9, marginLeft: 20 }} onClick={(e) => {
              e.preventDefault()
              setModalDados(true)
            }} />}
          </div>
          {(isDesktop ? true : (obj.id ? false : true)) &&


            <a className="logo-area" style={{ verticalAlign: 'center', textDecoration: 'none', fontSize: 12, width: 200 }}>


              <div className={`restly-header-main-menu ${className || ""}`} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }} >

                {!obj.nome &&
                  <ul style={{ display: 'flex', justifyContent: 'center' }}>

                    <li>
                      <Link onClick={() => {
                        localStorage.setItem("authPagehist", '/ferramentas-de-venda');
                        setModalLogin(true)
                      }} style={{ width: "100%", fontSize: 14 }} >
                        <><img src={boneco} style={{ width: isDesktop ? 15 : 18, marginRight: 2, marginBottom: 5 }} /> {isDesktop && "Área do Corretor"} </>
                      </Link>
                    </li>
                  </ul>
                }
                {obj.nome &&

                  <ul style={{ textAlign: 'center' }}>

                    <li>
                      <Link to="#"  >
                        <p style={{ textAlign: 'center', width: '100%' }} >   <img src={boneco} style={{ width: 15, marginRight: 2, marginBottom: 5 }} />  Olá, <span style={{ textTransform: 'capitalize' }}>{capitalizeFirstLetter(obj.nomefantasia ? obj.nomefantasia.split(' ')[0] : obj.nome.split(' ')[0])}</span>{" "}<i className="fa fa-angle-down" /></p>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <a
                            onClick={() => {
                              setModalDados(true);
                            }}
                            style={{
                              textAlign: 'left', color: '#222222', display: 'block', padding: '0px 15px', position: 'relative', color: '#505056', borderRadius: '4px', margin: '0 0', lineHeight: 2.5, textAlign: 'left'
                            }}
                          >
                            <img src={bonecoEditar} style={{ width: 15, marginBottom: 4, marginRight: '4px' }} />
                            Meu Perfil
                          </a>
                        </li>
                        {obj.permissao != 5 &&
                          <li >
                            <a onClick={handleLink}
                              style={{
                                textAlign: 'left', color: '#222222', display: 'block', padding: '0px 15px', position: 'relative', color: '#505056', borderRadius: '4px', margin: '0 0', lineHeight: 2.5, textAlign: 'left'
                              }} target="_blank" >
                              <GoGear size={15} style={{ marginRight: 2 }} color="#5c5c5c" />
                              Gerenciar
                            </a>
                          </li>

                        }
                        <li>
                          <a
                            href="/"
                            onClick={() => {
                              localStorage.setItem("authUser", "{}");
                              googleLogout();
                              window.location.href = '/'
                            }}
                            style={{
                              textAlign: 'left', color: '#222222', display: 'block', padding: '0px 15px', position: 'relative', color: '#505056', borderRadius: '4px', margin: '0 0', lineHeight: 2.5, textAlign: 'left'
                            }}
                          >
                            <RxExit size={15} style={{ marginRight: 4 }} color="#5c5c5c" />
                            Sair
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                }

              </div>

            </a>
          }
        </div >
      </div >
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
      <MeusDados modalDados={modalDados} setModalDados={setModalDados} />
    </>
  );
}
