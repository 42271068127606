import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench, BiHide } from "react-icons/bi";
//redux
// Formik validation

//redux
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";
// import images
//Import config

import { Api } from "../../../../api/apiLogin";
import { Api as ApiGeral } from "../../../../api";
import { Cadastro } from "../Cadastro";
import Esqueci from "../Recuperar";

import { GoogleLogin, GoogleOAuthProvider, googleLogout } from '@react-oauth/google';

export const Login = ({ modalLogin, setModalLogin }) => {
  //localStorage.setItem("authUser", JSON.stringify({}));
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [modalCadastro, setModalCadastro] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const validatePassword = () => {

    const regex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8}$/;
    setIsValid(!regex.test(password));
  };
  const [cep, setCep] = useState("");
  const [email, setEmail] = useState("");
  const [numero, setNumero] = useState("");
  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const [codigo5, setCodigo5] = useState("");
  const [codigo6, setCodigo6] = useState("");
  const [codigo7, setCodigo7] = useState("");
  const [codigo8, setCodigo8] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState();
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [id, setid] = useState();

  const [modalCelular, setModalCelular] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [modalEndereco, setModalEndereco] = useState(false);
  const [modalSenha, setModalSenha] = useState(false);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  useEffect(() => {
    const validarEmail = () => {
      // Expressão regular para validar um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const formatarCelular = () => {
      // Adiciona o prefixo "55" e formata o número do celular
      if (celular) {
        if (celular?.startsWith("55")) {
          return (
            "55" +
            celular
              .substring(2)
              .replace(/\D/g, "")
              .replace(/^(\d{2})(\d)/g, "($1) $2")
              .replace(/(\d)(\d{4})$/, "$1-$2")
          );
        } else {
          return celular
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g, "($1) $2")
            .replace(/(\d)(\d{4})$/, "$1-$2");
        }
      } else {
        return celular;
      }
    };

    const formatarCPF = () => {
      // Adiciona pontos e traços ao CPF
      return cpf
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    };

    if (validarEmail()) {
      // E-mail válido, pode ser utilizado
      // console.log("E-mail válido:", email);
    }

    setCelular(formatarCelular());
    setCpf(formatarCPF());
  }, [email, celular, cpf]);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCelularChange = (event) => {
    const inputCelular = event.target.value;
    // Limita o número de caracteres do celular em 15
    if (inputCelular.length <= 15) {
      setCelular(inputCelular);
    }
  };
  function handleInputChange(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input1Ref.current) {
        input2Ref.current.focus();
      } else if (e.target === input2Ref.current) {
        input3Ref.current.focus();
      } else if (e.target === input3Ref.current) {
        input4Ref.current.focus();
      } else if (e.target === input4Ref.current) {
        // aqui você pode adicionar o código para passar para o próximo campo de texto
      }
    }
  }
  function handleInputChangeEmail(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input5Ref.current) {
        input6Ref.current.focus();
      } else if (e.target === input6Ref.current) {
        input7Ref.current.focus();
      } else if (e.target === input7Ref.current) {
        input8Ref.current.focus();
      } else if (e.target === input8Ref.current) {
        // aqui você pode adicionar o código para passar para o próximo campo de texto
      }
    }
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor entre com seu Usuário!"),
      password: Yup.string().required("Por favor entre com sua senha!")
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let json = await Api.login(values);
      if (json.error) {
        setError(json);
        setid(json.id);
        if (json.validaemail == false) {
          setModalEmail(true);
          setEmail(json.email);
        }
        if (json.validacelular == false) {
          setModalCelular(true);
          setCelular(json.celular);
        }
        if (json.validaendereco == false) {
          setModalEndereco(true);
        }
        if (json.validasenha == false) {
          setModalSenha(true);
        }
      } else {
        setModalLogin(false);
        let page =localStorage.getItem("authPagehist"); 
        window.location.href = ((page && page!='null') ? page :'/')
      }
      setLoading(false);
    }
  });
  const handleValidarCelular = async () => {
    setLoading(true);
    let valida = await Api.validarCelular({
      id: id,
      codigo: `${codigo1}${codigo2}${codigo3}${codigo4}`,
      celular: celular
    });

    if (valida.error == false) {
      setCodigo1("");
      setCodigo2("");
      setCodigo3("");
      setCodigo4("");
      setModalCelular(false);
      alert("Celular validado!");
    } else {
      alert(valida.msg);
    }
    setLoading(false);
  };
  const handleValidarSenha = async () => {
    setLoading(true);
    if (password == passwordConfirm && !isValid) {
      let valida = await Api.validarSenha({ id: id, password: password });

      if (valida.error == false) {
        setCodigo1("");
        setCodigo2("");
        setCodigo3("");
        setCodigo4("");
        setModalSenha(false);
        alert("Senha alterada!");
      } else {
        alert(valida.msg);
      }
    } else {
      alert("As senhas precisam ser iguais e obedecerem a regra");
    }

    setLoading(false);
  };
  const handleValidarEmail = async () => {
    setLoading(true);
    let valida = await Api.validarEmail({
      id: id,
      codigo: `${codigo5}${codigo6}${codigo7}${codigo8}`,
      email: email
    });
    if (valida.error == false) {
      setCodigo5("");
      setCodigo6("");
      setCodigo7("");
      setCodigo8("");
      setModalEmail(false);
      alert("Email validado!");
    } else {
      alert(valida.msg);
    }
    setLoading(false);
  };

  const handleValidarEndereco = async () => {
    setLoading(true);
    let valida = await Api.validarEnderenco({
      id: id,
      cep: cep,
      rua: info.logradouro,
      numero: numero,
      bairro: info.bairro,
      cidade: info.localidade,
      estado: info.uf,
      complemento1: info.complemento
    });
    if (valida.error == false) {
      setModalEndereco(false);
      alert("Endereço validado!");
    } else {
      alert(valida.msg);
    }
    setLoading(false);
  };
  const handleDisparoEmail = async () => {
    setLoading(true);
    let valida = await Api.disparoEmail({ email: email, id: id });
    alert("Email enviado!");
    setLoading(false);
  };
  const handleDisparoCelular = async () => {
    setLoading(true);
    let valida = await Api.disparoCelular({ celular: celular, id: id });
    alert("Mensagem enviada!");
    setLoading(false);
  };
  const [error, setError] = useState();

  const handleCEP = async (cep) => {
    let string = cep.replace(/\D/g, "");
    fetch(`https://viacep.com.br/ws/${string}/json`)
      .then((res) => res.json())
      .then((data) => {
        setInfo(data);
      });
  };
  const [esqueci, setModalEsqueci] = useState(false);
  const [handleHide, setHandleHide] = useState([]);
  const handleShowHide = async (type, status, index) => {
    if (status) {
      document.querySelector(`input[id="password${type}"]`).type = 'text'
    } else {
      document.querySelector(`input[id="password${type}"]`).type = 'password'
    }
    let valores = [...handleHide]
    valores[index] = !valores[index]
    setHandleHide(valores)
  }
  const [dominoNatuseg, setDominioNatuseg] = useState(false)
  useEffect(() => {

    if (validation.values.email.includes('natuseg') || validation.values.email.includes('corpar') || validation.values.email.includes('corretorparceiro')) {
      setDominioNatuseg(false)
    } else {
      setDominioNatuseg(false)
    }
  }, [validation.values.email])


  // Dentro da função onSuccess
  const onSuccess = async (response) => {
    const accessToken = response?.credential;
    let responses = await ApiGeral.loginSocialGoogle({ token: accessToken })
    if (responses.error) {
      alert(responses.msg)
    }

  };
  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_API_GOOGLE}>
        <Modal id="modal-center3" size="md" centered={true} isOpen={modalLogin}>
          <div className="modal-header" style={{ border: 'none' }} >
            <button type="button" onClick={() => { setModalLogin(false); }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ float: 'right' }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>

            <Row className="g-0">
              <Col lg={12} md={12} className="col-xxl-12">
                <div className="auth-full-page-content d-flex p-sm-2 p-1">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="auth-content my-auto">
                        <div className="text-center">

                          <p className="" style={{ color: '#000', fontSize: 20 }}>
                            Para usar essa funcionalidade<br />
                            é necessário entrar no sistema
                          </p>
                        </div>
                        <Form
                          className="custom-form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {error?.error ? (
                            <Alert color="danger">{error?.msg}</Alert>
                          ) : null}
                          <div className="mb-1">
                            <Label className="form-label">Usuário</Label>
                            <Input name="email" className="form-control" placeholder="CPF OU CNPJ" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="row"  >

                            <div className="col-12">
                              <Label className="form-label">Senha</Label>
                              <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <div style={{ flex: 2 }}>

                                  <Input id="password" name="password" value={validation.values.password || ""} type="password" placeholder="******" onChange={validation.handleChange} onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                        validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />

                                </div>
                                <div style={{ border: '2px solid #cfdbf1', padding: 4, borderRadius: '5px' }}>

                                  {handleHide[0] ? <BiShow size={20} onClick={() => { handleShowHide('', false, 0) }} /> : <BiHide size={20} onClick={() => { handleShowHide('', true, 0) }} />}
                                </div>
                              </div>

                            </div>
                            <div className="col-2" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', paddingLeft: 0 }} >

                            </div>
                            {validation.touched.password &&
                              validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>



                          <div className="row mb-4">
                            <div className="col">

                              <a
                                className="form-check-label"
                                onClick={() => {
                                  setModalEsqueci(true);
                                }}
                                style={{
                                  fontSize: 16,
                                  color: "#000",
                                  textDecoration: 'none'
                                }}
                              >
                                Esqueceu a senha? <span style={{ color: '#dc3545' }} >Clique aqui</span>
                              </a>

                              <div className="mt-3 " style={{ display: "flex", justifyContent: 'center' }}>
                                <button className="btn btn-primary btn-block" type="submit" disabled={loading} style={{width:160}} >
                                  Entrar
                                </button>

                              </div>

                            </div>
                          </div>
                        </Form>
                        {!dominoNatuseg &&
                          <div className="mt-5 text-center">
                            <p className=" mb-0" style={{  fontSize: 16,
                                  color: "#000"}}>
                              Não possui cadastro?{" "}

                            </p>
                            <button
                              onClick={() => {
                                setModalLogin(false);
                                setModalCadastro(true);
                              }}
                              className="btn btn-danger"
                            >
                              {" "}
                              Cadastre-se Grátis{" "}
                            </button>{" "}
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Modal isOpen={modalCelular} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">Validação</h5>
              <button
                type="button"
                onClick={() => {
                  setModalCelular(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p style={{ fontWeight: "bold", fontSize: 15 }}>
                Agora vamos verificar seu Celular, estamos enviando um codigo. Por
                favor digite-o a abaixo
              </p>
              <Row>
                <Col lg={12}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        Celular <small>+ (DDD)</small>
                      </Label>
                      <Input
                        name="celular"
                        maxLength={15}
                        autocomplete="new-password"
                        value={celular}
                        onChange={handleCelularChange}
                        className="form-control"
                        type="tel"
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo1}
                    ref={input1Ref}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCodigo1(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo2}
                    ref={input2Ref}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCodigo2(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo3}
                    ref={input3Ref}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCodigo3(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo4}
                    ref={input4Ref}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCodigo4(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success"
                disabled={loading}
                onClick={() => {
                  handleDisparoCelular();
                }}
              >
                Enviar token
              </button>
              <button
                className="btn btn-success"
                disabled={loading}
                onClick={() => {
                  handleValidarCelular();
                }}
              >
                Verificar
              </button>
            </div>
          </Modal>
          <Modal isOpen={modalEmail} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">Validação</h5>
              <button
                type="button"
                onClick={() => {
                  setModalEmail(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p style={{ fontWeight: "bold", fontSize: 15 }}>
                Agora vamos verificar seu Email, estamos enviando um codigo. Por
                favor digite-o a abaixo
              </p>
              <Row>
                <Col lg={12}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        E-mail
                      </Label>
                      <Input
                        name="email"
                        autocomplete="new-password"
                        value={email}
                        onChange={handleEmailChange}
                        className="form-control"
                        type="email"
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo5}
                    ref={input5Ref}
                    onChange={(e) => {
                      handleInputChangeEmail(e);
                      setCodigo5(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo6}
                    ref={input6Ref}
                    onChange={(e) => {
                      handleInputChangeEmail(e);
                      setCodigo6(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo7}
                    ref={input7Ref}
                    onChange={(e) => {
                      handleInputChangeEmail(e);
                      setCodigo7(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={codigo8}
                    ref={input8Ref}
                    onChange={(e) => {
                      handleInputChangeEmail(e);
                      setCodigo8(e.target.value);
                    }}
                    style={{ textAlign: "center" }}
                    maxLength={1}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success"
                disabled={loading}
                onClick={() => {
                  handleDisparoEmail();
                }}
              >
                Enviar token
              </button>
              <button
                className="btn btn-success"
                disabled={loading}
                onClick={() => {
                  handleValidarEmail();
                }}
              >
                Verificar
              </button>
            </div>
          </Modal>

          <Modal isOpen={modalEndereco} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">Validação</h5>
              <button
                type="button"
                onClick={() => {
                  setModalEndereco(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p style={{ fontWeight: "bold", fontSize: 15 }}>
                Precisamos que você coloque seu endereço atualizado!
              </p>
              <Row>
                <Col lg={12}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        CEP
                      </Label>
                      <Input
                        name="cep"
                        autocomplete="new-password"
                        onBlur={(e) => {
                          handleCEP(e.target.value);
                        }}
                        value={cep}
                        onChange={(e) => {
                          setCep(e.target.value);
                        }}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        Rua
                      </Label>
                      <Input
                        name="rua"
                        autocomplete="new-password"
                        disabled
                        value={info?.logradouro ? info?.logradouro : info?.rua}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        Número
                      </Label>
                      <Input
                        name="numero"
                        autocomplete="new-password"
                        value={numero}
                        onChange={(e) => {
                          setNumero(e.target.value);
                        }}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        Bairro
                      </Label>
                      <Input
                        name="bairro"
                        autocomplete="new-password"
                        disabled
                        value={info?.bairro ? info?.bairro : info?.bairro}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        Cidade
                      </Label>
                      <Input
                        name="cidade"
                        autocomplete="new-password"
                        disabled
                        value={info?.localidade ? info?.localidade : info?.cidade}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label">
                        Estado
                      </Label>
                      <Input
                        name="estado"
                        autocomplete="new-password"
                        disabled
                        value={info?.uf ? info?.uf : info?.estado}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success"
                disabled={loading}
                onClick={() => {
                  handleValidarEndereco();
                }}
              >
                Verificar
              </button>
            </div>
          </Modal>

         
        </Modal>
      </GoogleOAuthProvider>
      <Cadastro
        modalCadastro={modalCadastro}
        setModalCadastro={setModalCadastro}
        setModalLogin={setModalLogin}
      />
      <Esqueci modalEsqueci={esqueci} setModalEsqueci={setModalEsqueci} />
    </>

  );

};
