import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

export default function GallerySection() {
  const [list, setList] = useState();
  const handleDados = async () => {
    let list = await Api.areas();


    if (list?.list?.length > 0) {

      setList(list.list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: { slidesToShow: 4 }
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  }
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [list]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredVerMais, setIsHoveredVerMais] = useState(false);
  const handleMouse = () => {
    setIsHoveredVerMais(!isHoveredVerMais);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const textStyle = {
    textDecoration: isHovered ? 'underline' : 'none',
    cursor: 'pointer'
  };
  const textStyleVerMais = {
    textDecoration: isHoveredVerMais ? 'underline' : 'none',
    cursor: 'pointer'
  };
  return (
    <section className="pb-70" >
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: "center", marginTop: 25 }}  >
        <div className="" style={{ width: '90%' }}>
          <div >
            <a href={"/ramos"} >
              <span onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave} style={{ ...textStyle, color: '#092140', textAlign: 'center', fontSize: 22, padding: 8, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>Ramos de  <span style={{ color: '#2278F3' }} >Atuação</span></span>
            </a>
          </div>
          <div className="" style={{ marginTop: 8, marginBottom: 12, marginTop: 20, display: 'flex' }} >
            <div className="" style={{ margin: 'auto', width: '10%' }}>
              <MdArrowBackIos onClick={prevHandler} color="#ccc" size={20} />
            </div>
            <div className=" " style={{ width: '90%' }} >
              <div className="logo-carousel-wrap " >
                <SliderCom settings={settings} selector={sliderRef}>
                  {list &&
                    list.map((item, index) => (
                      <a href={`/ramos/${item.id}`} tabIndex={-1} id={item.id} key={item.id} style={{ cursor: "pointer", marginBottom: 0 }} >
                        <div key={item?.id} >
                          <a style={{ margin: '25px 10px 10px', boxShadow: ' 1px 4px 4px 1px #00000040', borderRadius: 10, display: 'flex', flexDirection: "column", justifyContent: 'space-around', alignItems: 'center', height: 200, border: '0.01px solid #ccc' }} >
                            <div style={{ height: 130}}>
                              <img src={`https://server.corpar.com.br/imagens/${item?.imagem ? item?.imagem : item?.icone}`} style={{ height: 130 }} />
                            </div>
                            <div style={{ height: 50, display: 'flex', justifyContent: 'flex-end' }}>
                              <p style={{ textAlign: 'center', fontSize: 16, color: '#092140', width: 160, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4 }}>
                                {item.nome.split('\n').map((linha, index) => (
                                  <React.Fragment key={index}>
                                    {linha}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </p>
                            </div>
                          </a>
                        </div>
                      </a>


                    ))}
                </SliderCom>
              </div>
            </div>
            <div className="" style={{ margin: 'auto', width: '10%' }}>
              <MdArrowForwardIos onClick={nextHandler} color="#ccc" size={20} />
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <a href={"/ramos"} >
              <span onMouseEnter={handleMouse}
                onMouseLeave={handleMouse} style={{ ...textStyleVerMais, color: '#2278F3', textAlign: 'center', fontSize: 16, padding: 8, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>Ver mais</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
