import React, { useEffect, useState } from "react";
import { Api } from "../../api";

export default function MainSection() {
  const [listOriginal, setListOriginal] = useState();
  const [list, setList] = useState();
  const [ultimas, setUltimas] = useState();
  const [escolha, setEscolha] = useState();
  const [categorias, setCategorias] = useState();
  const getAuthUser = localStorage.getItem("authUser");
  const obj =
    getAuthUser == "undefined" ||
      getAuthUser == null ||
      getAuthUser == "[object Object]"
      ? ""
      : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj =
      getAuthUser == "undefined" ||
        getAuthUser == null ||
        getAuthUser == "[object Object]"
        ? ""
        : JSON.parse(getAuthUser ? getAuthUser : "");

  }, [obj])
  const handleDados = async () => {
    let json = await Api.treinamentos();
    let list = json?.list
    setListOriginal(list)
    setList(list)
  };
  useEffect(() => {
    handleDados();
  }, []);

  const handleBuscar = async (buscar) => {
    let listagem = listOriginal
    listagem = listagem.filter((item) =>(buscar?  item.nome.toUpperCase().includes(buscar.toUpperCase()):true))
    setList(listagem)
  }



  return (
    <section className="blog-page-area bg-lighter">
      <div className="container">
        <div className="row" style={{height:'100vh'}}>
          <div className="col-lg-9" style={{backgroundColor:'#F6F7F9'}}>
            <div className="blog-standard-content pt-20 rpt-100 pb-110 ">
              {list &&
                list.map((item,index) => (
                  <div key={index} style={{ margin: '0px 15px 15px',width:'100%',borderRadius: 10, borderBottom:'2px solid #E5EEF6' }}>
                    <div className="blog-header" style={{ top: 0 }}>
                      <ul className="post-meta-item mr-15">
                        <li>
                          <i className="fas fa-calendar-alt"></i>
                          <a href="#">{item?.data}</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-standard-item wow fadeInUp delay-0-2s" style={{ display: 'flex', alignItems: 'flex-start',marginBottom:10}}  >

                    
                      <div style={{ flex: 1, display: "flex", alignItems: 'flex-start', flexDirection: "column", justifyContent: "space-around", height: '100%' }}  >

                        <h3>
                          <a href="/blog/blog-details">{item?.nome}</a>
                        </h3>
                        <p>{item?.descricao}</p>
                        <p><a href={item?.url} >Clique aqui para acessar</a> </p>
                      </div>

                      <div className="" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        {item?.imagem && (
                          <img
                            src={`https://server.corpar.com.br/imagem/${item?.imagem}`}
                          />
                        )}
                        {item?.video && (
                          <video width="280" height="220" controls>
                            <source
                              src={`https://server.corpar.com.br/video/${item?.video}`}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-lg-3 col-sm-9">
            <div className="blog-sidebar mt-10 rmy-100">
              <div className="widget widget-search wow fadeInUp delay-0-2s" style={{borderRadius:6}}>
                <form action="#">
                  <input type="text" placeholder="Buscar..." className="searchbox"
                    required
                    onChange={(e) => { handleBuscar(e.target.value) }}
                  />
                  <button
                    type="submit"
                    className="searchbutton fa fa-search"
                  ></button>
                </form>
              </div>
             
           

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
