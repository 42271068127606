import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import Listagem from "./utils/listagem";
import Icone from "../../assets/icones/icone.png"
function FeatureSection(val) {
  const [operadoraSaude, setOperadoraSaude] = useState();
  const [datas, setDatas] = useState([]);
  const { tipo } = useParams();
  const [estados, setEstados] = useState([])
  const [estadosSelect, setEstadosSelect] = useState('')
  const [planoSelect, setPlanoSelect] = useState('')
  const [obj, setObj] = useState();
  const [loading, setLoading] = useState(false)
  const [ramos, setRamos] = useState([])
  const [ramo, setRamo] = useState('')
  const [tipoRamo, setTipoRamo] = useState('')
  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
    setObj(obj)
    handleRamos()
  }, [])
  useEffect(() => {
    if (obj?.dados?.estado && estados.length > 0) {
      let ids = estados?.find((item) => item.nome == obj?.dados?.estado)
      setEstadosSelect(ids?.id)
    }
  }, [obj, estados])

  const handleRamos = async () => {
    let value = await Api.ramosVendaOline()
    let result = value.list.filter((item) => item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? true : item.operadoraslinks?.some((item) => item.link))
    result = result.map((item) => { return { id: item.id, nome: item.nome, tipo: item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? 'Odonto-Saude' : item.operadoraslinks.some((item) => item.link) ? 'Outros' : '' } })
    let uniqueArray = result.reduce((acc, current) => {
      const exists = acc.find(item => item.id === current.id);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);
    uniqueArray.sort((a, b) => a.ordem - b.ordem)
    setRamos(uniqueArray)
  }
  const handleSelectRamo = async (value) => {
    setRamo(value)
    let result = ramos.find((find) => find.id == Number(value))
    setTipoRamo(result.tipo)
  }

  useEffect(() => {
    if(ramo && tipoRamo){
      if(tipoRamo=='Odonto-Saude'){
        if(estadosSelect){
          handleOperadora()
        }else{
          setDatas([]);
        }
      }else if(tipoRamo=='Outros'){
        handleOperadora()
      }
    }

  }, [tipoRamo,ramo,estadosSelect])


  const handleEstados = async () => {
    let value = await Api.estadosSaude()
    setEstados(value.list)
  }
  const handleOperadora = async () => {
    
    setLoading(true)
    setDatas([]);
    let list = await Api.operadoraTipo({ tipo:ramo,estado: tipoRamo=='Odonto-Saude'?estadosSelect:'' });
    let variavel = list.list.filter((item) => ((item.linkvenda != null) && (item.linkvenda!='') || (item.link != null) && (item.link!='') ) );
    let valores = variavel.map((item) => {
      return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa, link: item.linkvenda || item.link,usuario: item.usuario && item.linkUsuario };
    });
  
    setDatas({ valores, estado: estadosSelect });
    setLoading(false)
  };
  useEffect(() => {
    handleEstados();
    if (tipo == 'saude') {
      setPlanoSelect(1)
    } else if (tipo == 'odonto') {
      setPlanoSelect(2)
    }
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
      <div className="container">
        <div className="row" style={{ marginBottom: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <div className={planoSelect == 1 || planoSelect == 2 ? 'col-4' : 'col-4'}  >

            <select className="form-control" name="estados" onChange={(e) => { handleSelectRamo(e.target.value) }} >
              <option value=''>Escolha o Ramo</option>
              {ramos.map((item) => (
                <option key={'ramo-' + item.id} value={item.id}>{item.nome}</option>
              ))}
            </select>
          </div>

          {(tipoRamo == 'Odonto-Saude') &&
            <div className="col-4" >

              <select className="form-control" name="estados" value={estadosSelect} onChange={(e) => { setEstadosSelect(e.target.value) }} >
                <option value=''>Escolha o Estado</option>
                {estados && estados.map((item) => (
                  <option key={item} value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>
          }

        </div>
        {(tipoRamo?(tipoRamo == 'Odonto-Saude' && estadosSelect)?true:tipoRamo:tipoRamo) && !loading && datas.valores?.length>0 && <Listagem datas={datas} tipoRamo={tipoRamo} />}
        {loading && (
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={Icone} className="rotating-image" />
          </div>
        )}

        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default FeatureSection;
