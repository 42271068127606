import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import card from "../../../assets/images/icones/card.png";
import carrinho from "../../../assets/images/icones/carrinho.png";
import dente from "../../../assets/images/icones/dente.png";
import estetoscopio from "../../../assets/images/icones/estetoscopio.png";
import gerenciar from "../../../assets/images/icones/gerenciar.png";
import guardachuva from "../../../assets/images/icones/guardachuva.png";
import sino from "../../../assets/images/icones/sino.png";
import megafone from "../../../assets/images/icones/megafone.png";
import { BiDetail } from "react-icons/bi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { LuDog } from "react-icons/lu";
export default function Hero({ pageTitle, breadcrumbs = [] }) {
  const [list, setList] = useState();
  const icones = [
    {name:"Planos de Saúde",img:true,imagem:estetoscopio},
    {name:'Plano de Odontológico',img:true,imagem:dente},
    {name:'Planos Pet',img:false,imagem: <LuDog style={{marginRight:3}} />},
    {name:'Ferramentas de Vendas',img:true,imagem:gerenciar},
    {name:'Cotações',img:true,imagem:card},
    {name:'Venda Online',img:true,imagem:carrinho},
    {name:'Tabelas Personalizadas',img:true,imagem:card},
    {name:'Seguros e Ramos de Atuação',img:true,imagem:guardachuva},
    {name:'Materiais de Divulgação',img:true,imagem:megafone},
    {name:'Comunicados',img:true,imagem:sino},
    {name:'Ramos de Atuação',img:false,imagem:<BiDetail  size={25} style={{ marginRight: 4 }}/>},
    {name:'Operadoras',img:false,imagem:<HiOutlineBuildingOffice2  size={25} style={{ marginRight: 4 }} color="#fff"/>}
]
  const handleDados = async () => {
    let list = await Api.banner();
    if (list?.list?.id) {
      setList(list.list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  return (
    <section
      className="page-banner bgs-cover overlay pt-1"
      style={{ 
        backgroundColor:'#f6f7f9',
         minHeight: 150, display:'block'}}
    >
    
      <div className="container"  >
        <div className="banner-inner">
          <h1 className="page-title" style={{fontSize:23,fontWeight:100, marginTop:80,color:'#092140',display:"flex"}} > 
          
        {pageTitle}</h1>
          <nav aria-label="breadcrumb" style={{marginTop:-10}}>
            <ol className="breadcrumb">
              {breadcrumbs &&
                breadcrumbs.length > 0 &&
                breadcrumbs.map((item) => (
                  <li key={Math.random()} className="breadcrumb-item" style={{color:'#092140'}}>
                    <a href={item.path} style={{fontWeight:100,color:'#092140'}}> {item.name}</a>
                  </li>
                ))}
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
}
