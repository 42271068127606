import React from "react";
import FooterHomeTwo from "../footer";
import HeaderHomeOne from "../Header";
import Hero from "./Hero";

export default function Layouts({ children, pageTitle, breadcrumbs = [] }) {
  return (
    <>
      <div style={{ minHeight: '88vh' }}>
        <HeaderHomeOne />

        <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
        {children && children}


      </div>
      <FooterHomeTwo />
    </>
  );
}
