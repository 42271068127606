import React from "react";
import Layouts from "../Corpo/Layouts";
import Dados from "./index";

export default function Treinamentos() {
  return (
    <Layouts
      pageTitle="Treinamentos"
      breadcrumbs={[
        { name: "Inicio", path: "/" },
        { name: "Treinamentos", path: "/treinamentos" }
      ]}
    >
      <Dados />
    </Layouts>
  );
}
