import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
const getAuthUser = localStorage.getItem('authUser')
const obj = getAuthUser ? JSON.parse(getAuthUser ? getAuthUser : '') : ''

export const Visualizar = ({ faixas, info, modalVisualizar, nomeProduto, setModalVisualizar, handleCarregamento }) => {
  const divRef = useRef(null);
  const [tabela, setTabela] = useState([])
  const dataAtual = () => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    const data = new Date();
    const mes1 = meses[data.getMonth()];
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // o valor do mês começa em 0, então é necessário adicionar 1
    const ano = data.getFullYear();
    return { data1: `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano}`, data2: `${mes1}/${ano}` }
  }
  useEffect(() => {
    handleCarregamento(false)
  }, [modalVisualizar])
  const organizar = () => {
    setTabela([])
    let produtos = info?.nome
    let coberturas = info?.cobertura
    let acomodacaos = info?.acomodacao
    let coparticipacaos = info?.coparticipacao
    let precoVidas = info?.precovida
    let faixas2 = info?.faixa
    let tabelas = [{ nome: [], cobertura: [], acomodacao: [], coparticipacao: [], faixas: [], precoVida: [] }]
    let cont = 0
    let contItem = 0
    let arrayFaixa = []
    if (produtos?.length > 0) {
      for (let index = 0; index <= produtos.length; index++) {
        cont = cont + 1
        let nome = produtos ? produtos[index] ? produtos[index] : '' : ''
        let cobertura = coberturas ? coberturas[index] ? coberturas[index] : '' : ''
        let acomodacao = acomodacaos ? acomodacaos[index] ? acomodacaos[index] : '' : ''
        let coparticipacao = coparticipacaos ? coparticipacaos[index] ? coparticipacaos[index] : '' : ''
        let faixa = faixas2 ? faixas2[index] ? faixas2[index] : [] : []
        let precoVida = precoVidas ? precoVidas[index] ? precoVidas[index] : '' : ''
        if (cont <= 5) {
          if (!(nome == '' && cobertura == '' && acomodacao == '' && coparticipacao == '' && precoVida == '')) {
            tabelas[contItem].nome.push(nome)
            tabelas[contItem].cobertura.push(cobertura)
            tabelas[contItem].acomodacao.push(acomodacao)
            tabelas[contItem].coparticipacao.push(coparticipacao)
            tabelas[contItem].precoVida.push(precoVida)
            arrayFaixa.push(faixa)
          }
        } else {
          let juntar = juntarNomesIguais(arrayFaixa)
          tabelas[contItem].faixas.push(juntar)
          arrayFaixa = []
          contItem = contItem + 1
          tabelas.push({ nome: [], cobertura: [], acomodacao: [], coparticipacao: [], faixas: [], precoVida: [] })
          cont = 0
        }
        if (index == produtos.length) {
          let juntar = juntarNomesIguais(arrayFaixa)
          tabelas[contItem].faixas.push(juntar)
          arrayFaixa = []
          contItem = contItem + 1


        }
      }
    }

    setTabela(tabelas)
  }
  useEffect(() => {
    organizar()
  }, [info])
  function juntarNomesIguais(array) {
    const resultado = [];
    const mapNomes = new Map();

    array.forEach((subarray) => {
      subarray.forEach((item) => {
        const nome = item.nome;
        if (!mapNomes.has(nome)) {
          mapNomes.set(nome, { id: item.id, nome, valor: item.valor });
        } else {
          const objExistente = mapNomes.get(nome);
          objExistente.valor.push(...item.valor);
        }
      });
    });

    mapNomes.forEach((obj) => {
      obj.valor.sort((a, b) => a - b)
      resultado.push(obj);
    });
    resultado.sort((a, b) => a.id - b.id);

    return resultado;
  }
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [info]);
  return (
    <Modal
      id="modal-center3"
      size="xl"
      isOpen={modalVisualizar}
      centered={true}
    >
      <div className="modal-header">

        <button
          type="button"
          onClick={() => {
            setModalVisualizar(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div className="modal-body" id='minha-div' ref={divRef}>

        <div style={{ display: 'flex', justifyContent: "center", padding: 40 ,flexDirection:isDesktop?'row':'column'}}>

          <div style={{ flex: 1,flexWrap:'wrap',  }}>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>{obj.nome}</p>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>Celular: {obj.dados.celular}</p>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>Email:{obj.email}</p>
          </div>
          <div style={{ flex: 3 ,flexWrap:'wrap' }}>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>Tabela Nº: {info?.id}</p>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>Estado: {info?.estado} </p>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>Gerado em {dataAtual().data1}</p>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>Vigência: {dataAtual().data2}</p>
            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140' }}>*Preços, condições e critérios de aceitação, estão sujeitos a confirmação da operadora no processo de fechamento do condivato</p>
          </div>

        </div>

        <div style={{ overflowX: 'auto' }}>


          <Col lg={12}>

            <div style={{ backgroundColor: '#ccc', fontSize: 15, textAlign: 'center', color: '#092140', fontWeight: 'bold' }} >{nomeProduto}</div>
          </Col>


          <div id='tableContainer'>

          </div>


          {tabela && tabela.map((item, index) => (
            <table key={index} className="table" id="originalTable" style={{ marginBottom: 40 }}>
              <thead>
                <tr>

                  <>


                  </>

                </tr>
              </thead>
              <tbody>
                {!item?.nome.every((element) => element === "") &&
                  <tr >
                    <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }} >Produto</th>
                    {item && item.nome?.map((item2, index2) => (
                      <th key={`Nome-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100 }} >{item2}</th>
                    ))}
                  </tr>
                }


                {item?.precoVida.length > 0 && !item?.precoVida.every((element) => element === "") &&
                  <tr >
                    <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }} >Preço/Vida</th>
                    {item && item?.precoVida?.map((item2, index2) => (
                      <th key={`Preco-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100 }} >R$ {item2}</th>
                    ))}
                  </tr>
                }

                {item?.cobertura.length > 0 && !item?.cobertura.every((element) => element === "") &&
                  <tr >
                    <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }} >Cobertura</th>
                    {item && item?.cobertura?.map((item2, index2) => (
                      <th key={`cobertura-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100 }} >{item2}</th>
                    ))}
                  </tr>
                }
                {item?.acomodacao.length > 0 && !item?.acomodacao.every((element) => element === "") &&
                  <tr >
                    <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }} >Acomodação</th>
                    {item && item?.acomodacao?.map((item2, index2) => (
                      <th key={`acomodacao-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100 }} >{item2}</th>
                    ))}
                  </tr>
                }
                {item?.coparticipacao.length > 0 && !item?.coparticipacao.every((element) => element === "") &&
                  <tr >
                    <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }} >Coparticipação</th>

                    {item && item?.coparticipacao.length > 0 ? item?.coparticipacao?.map((item2, index2) => (
                      <>
                        {item2.nome &&
                          <th key={`Coparticipacao-${index2}-${index}`} style={{ textAlign: 'center' }} >
                            {(item2.nome == 'Sim' || item2.nome == "Total" || item2.nome == "Parcial") ? `Com coparticipacão ${item2.nome == "Total" || item2.nome == "Parcial" ? `(${item2.nome})` : ""}  ${(item2.status && item.percentual) ? 'de  ' + item2.percentual + '%' : ''}` : 'Sem coparticipacão'} </th>
                        }
                      </>


                    )) : <th style={{ textAlign: 'center', fontWeight: 100 }} >Sem coparticipacão</th>}
                  </tr>
                }





                {item?.faixas.length > 0 ? item?.faixas?.map((item4, cont1) => (

                  <>


                    {item4.map((item5, cont2) => (
                      <tr key={`Faixa-${cont1}-${cont2}`} >
                        <th style={{ textAlign: 'center' }} >{item5.nome}</th>
                        {item5.valor.map((item6, cont3) => (
                          <th key={`Faixas-${cont1}-${cont2}-${cont3}`} style={{ textAlign: 'center', fontWeight: 100 }} >{item6}</th>
                        ))}
                      </tr>
                    ))}

                  </>


                )) : ''}



              </tbody>
            </table>

          ))}



          <div id="tableContainer"></div>

          {info?.entidades && info.entidades.length > 0 ?
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Entidades e Profissões</p>

              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open' }}  >
                  {info && info?.nome?.map((item, index) => (
                    <span key={`Entidades-${index}`}>
                      {item.entidades && item.entidades.map((item2) => (
                        <> - {item2.nome} -</>
                      ))}
                    </span>

                  ))}

                </div>
              </Col>



            </>
            : ''}



          {info?.entidades && info.entidades.length > 0 ?
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Entidades e Profissões</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open' }}  >
                  {info && info?.nome?.map((item, index) => (
                    <span key={`Entidades-${index}`}>
                      {item.entidades && item.entidades.map((item2) => (
                        <> - {item2.nome} -</>
                      ))}
                    </span>

                  ))}

                </div>
              </Col>
            </>
            : ''}


          {info?.observacoes?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Observações Importantes</p>
              <p style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>"Esta simulação poderá variar de acordo com o perfil do cliente, a critério da operadora. Preços, condições e regras de aceitação, estão sujeitas a confirmação da operadora no processo de implantação do contrato."</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }} >
                  {info && info?.observacoes?.map((item, index) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>
                  ))}
                </div>
              </Col>
            </>
          }


          {info?.rede?.length > 0 &&
            <>
              <p style={{ fontSize: 17, backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -6, textAlign: 'center' }}>Rede Referenciada</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}  >
                  {info && info?.rede?.map((item) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>
                  ))}
                </div>
              </Col>
            </>
          }


          {info?.reembolso?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Opcionais, diferenciais e reembolsos</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}  >
                  {info && info?.reembolso?.map((item) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>
                  ))}
                </div>
              </Col>
            </>

          }

          {info?.areacomercializacao?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Áreas de comercialização e Atendimento  </p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}  >
                  {info && info?.areacomercializacao?.map((item) => (
                    <  >
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      {item?.municipios.map((item3, index) => (
                        <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.munici)} -` }}  ></span>
                      ))}

                    </>

                  ))}

                </div>
              </Col>
            </>
          }

          {info?.carencia?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Carências</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}  >
                  {info && info?.carencia?.map((item) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>

                  ))}

                </div>
              </Col>
            </>
          }

          {info?.dependente?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Dependentes</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}   >
                  {info && info?.dependente?.map((item) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>

                  ))}

                </div>
              </Col>
            </>
          }

          {info?.documento?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Documentos Necessários</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}  >
                  {info && info?.documento?.map((item) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>


                  ))}

                </div>
              </Col>
            </>
          }

          {info?.pagamento?.length > 0 &&
            <>
              <p style={{ backgroundColor: '#E9E9EF', padding: 10, marginTop: 15, marginBottom: -1, textAlign: 'center', fontSize: 17 }}>Formas de Pagamentos</p>
              <Col lg={12}>
                <div className='form-control' style={{ borderColor: '#E9E9EF', borderWidth: 1, fontSize: 14, fontFamily: '"Open Sans", Open', height: '100%' }}  >
                  {info && info?.pagamento?.map((item) => (
                    <>
                      <p style={{ fontWeight: 'bold' }} >
                        {item?.produto.map((item2) => { return `/ ${item2} /` })}
                      </p>
                      <p >
                        <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                      </p>
                    </>

                  ))}

                </div>
              </Col>
            </>
          }
        </div>
      </div>

    </Modal>
  )
}