
import React, { useEffect, useRef, useState } from "react";
const getAuthUser = localStorage.getItem('authUser')
const obj = getAuthUser ? JSON.parse(getAuthUser ? getAuthUser : '') : ''

function TermosDeUso() {

  return (
    <section className="featured-section bgs-cover pt-20  pb-20 rpb-100" style={{ minHeight: 850 }}>
      <div className="container"  >



        <div className="feature-btn  mt-20" style={{textAlign:'justify'}}>

          {`Aqui está um exemplo de um termo de uso gerado aleatoriamente (lorem ipsum) que você pode usar como base. Você pode personalizá-lo conforme necessário para atender às suas necessidades específicas:

          ---

          ## Termos de Uso

          ### 1. Aceitação dos Termos
          Ao acessar e usar nossos serviços, você concorda em cumprir e se vincular a estes Termos de Uso. Se você não concordar com qualquer parte desses termos, você não deve usar nossos serviços.

          ### 2. Alterações nos Termos
          Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. Quaisquer alterações entrarão em vigor imediatamente após a publicação no site. O uso contínuo dos serviços após tais alterações constituirá sua aceitação dos novos termos.

          ### 3. Uso dos Serviços
          Você concorda em usar os serviços apenas para fins legais e de acordo com todas as leis e regulamentos aplicáveis. Você não deve usar os serviços de qualquer maneira que possa danificar, desativar, sobrecarregar ou comprometer nossos sistemas.

          ### 4. Conta de Usuário
          Para acessar certas funcionalidades dos serviços, você pode precisar criar uma conta. Você concorda em fornecer informações precisas e completas durante o processo de registro e em manter sua conta atualizada. Você é responsável por manter a confidencialidade de sua senha e conta.

          ### 5. Propriedade Intelectual
          Todos os conteúdos, marcas registradas e outros direitos de propriedade intelectual relacionados aos serviços são de nossa propriedade ou licenciados para nós. Você não pode reproduzir, distribuir ou criar trabalhos derivados de qualquer parte dos serviços sem nossa autorização expressa.

          ### 6. Limitação de Responsabilidade
          Na medida máxima permitida pela lei, não seremos responsáveis por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos decorrentes do uso ou da incapacidade de usar os serviços.

          ### 7. Indenização
          Você concorda em indenizar e isentar de responsabilidade a empresa, seus diretores, funcionários e agentes de qualquer reclamação, responsabilidade, danos, perdas, custos ou despesas decorrentes do seu uso dos serviços.

          ### 8. Lei Aplicável
          Estes Termos de Uso serão regidos e interpretados de acordo com as leis do [seu estado ou país], sem levar em conta os princípios de conflitos de leis.

          ### 9. Contato
          Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco através do e-mail: [seu-email@exemplo.com].

          ---

          Lembre-se de adaptar o conteúdo para refletir as políticas e práticas reais da sua empresa, especialmente em seções como "Limitação de Responsabilidade" e "Indenização". É sempre aconselhável consultar um advogado para garantir que seus termos estejam em conformidade com as leis aplicáveis.`}
        </div>
      </div>
    </section>
  );
}
export default TermosDeUso;
