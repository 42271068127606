import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import Icone from "../../../assets/icones/icone.png"
import { TbFilterDown } from "react-icons/tb";
import { TbFilterUp } from "react-icons/tb";

export const VisualizarDetalhe = ({ faixas, info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, handleClick, selecteds, handleMouseOut, handleMouseOver, handleCarregamento, handleVisualizarCotacoes, loadingDetalhes }) => {

  const handleOrdenarMenor = () => {
    let selecionado = document.querySelector('button[id="1"]')
    let deselecionado = document.querySelector('button[id="2"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-default'
      selecionado.style.border = '1px solid #4180D6'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.border = '1px solid #000'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }
      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => a[1] - b[1]);
      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  const handleOrdenarMaior = () => {
    let selecionado = document.querySelector('button[id="2"]')
    let deselecionado = document.querySelector('button[id="1"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-default'
      selecionado.style.border = '1px solid #4180D6'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.border = '1px solid #000'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => b[1] - a[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  useEffect(() => {

  }, [])
  const [municipios, setMunicipios] = useState([])
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const handleMunicipios = (municipio) => {
    setMunicipios(municipio)
    setModalMunicipios(true)
  }
  return (
    <Modal
      id="modal-center3"
      size="xl"
      isOpen={modalVisualizarDetalhe}
      centered={true}
      style={{ maxWidth: 1200 }}
    >

      <div className="modal-body" style={{ width: '100%', overflowX: 'auto' }}>
        <Row >
          <div className="col-12" >
            <button
              type="button"
              onClick={() => {
                setModalVisualizarDetalhe(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ float: 'right', marginRight: 10 }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        </Row>

        {loadingDetalhes ?
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Icone} className="rotating-image" />
          </div>
          :
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
              <div style={{ float: 'right', marginTop: 30, marginRight: 10 }}>
                <button id='1' className="btn btn-default" onClick={() => {
                  handleCarregamento(true)
                  handleOrdenarMenor()
                }} style={{ marginRight: 10 }} >Menor Preço  <TbFilterDown /></button>
                <button id='2' className="btn btn-default" onClick={() => {
                  handleCarregamento(true)
                  handleOrdenarMaior()
                }} style={{ marginRight: 10 }} >Maior Preço <TbFilterUp /></button>

              </div>
            </div>


            <div onClick={() => { handleVisualizar(index, !operadora.visualizar) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginRight: 25, marginLeft: 25, boxShadow: '0 0 0 0.5px #ccc', borderRadius: 6, marginBottom: 0 }}>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={`https://server.corpar.com.br/imagens/${info?.imagem}`} width='200px' style={{ margin: 'auto', marginRight: 10 }} />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-10px' }} >
                  {/* {info?.operadora} */}
                </div>




              </div>

            </div>





            <table className="table" style={{ marginTop: 0 }} >
              <thead>

              </thead>
              <tbody id='tabela' style={{ display: "flex", paddingLeft: "25px", paddingRight: "25px", flexDirection: "column" }} >


                {info && info.produto?.map((item, index) => (
                  <tr className="tr" id={`${item?.id}`}

                    onMouseOut={() => { handleMouseOut(item.id) }} onMouseMove={() => { handleMouseOver(item.id) }}
                    style={selecteds.some((item3) => item3.idtabela === item.id) ? { backgroundColor: '#fff', border: 0, borderLeft: 0, borderRight: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 10 } : { textAlign: 'center', borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', border: 0, borderLeft: 0, borderRight: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 10 }}

                    key={item?.id} >

                    <p style={{ fontWeight: 'bold', fontSize: 15, height: 70, lineHeight: 1.3, textAlign: 'center', width: '100%', backgroundColor: 'rgb(204, 204, 204,0.5)', borderRadius: 6, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >



                      <input type="checkbox" style={{ width: 10, left: 0, height: 10, border: '1px solid #ccc', position: 'absolute', marginTop: 5, marginLeft: 50, borderRadius: '50%' }} defaultChecked={selecteds.some((item3) => item3.idtabela === item.id)} />

                      {item.nome}<br /> {item.cobertura ? `${item.cobertura}` : ''}  {item.vidas} {item.acomodacao ? `- ${item.acomodacao} - ` : ''} {(item.coparticipacao == 'Sim' || item.coparticipacao == "Total" || item.coparticipacao == "Parcial") ? `Com coparticipacão ${item.coparticipacao == "Total" || item.coparticipacao == "Parcial" ? `(${item.coparticipacao})` : ""} ${item.percentual ? 'de  ' + item.percentual + '%' : ''}` : 'Sem coparticipacão'}</p>
                    <tr style={{ width: '100%' }}>
                      <table className="table" style={{ cursor: 'pointer', marginBottom: 0 }}>
                        <tbody >
                          <tr style={{ border: 0 }}>

                            {item?.faixas.filter((filter) => filter.cotacoesfaixa?.valor)?.map((item1, index2) => (
                              <td key={`${item.id}-faixas-${index}-${index + index2}-${index2}-${item1?.cotacoesfaixa?.valor}`} style={{ textAlign: 'center', border: 0, lineHeight: 1, padding: 5, border: 'none', width: 95 }} >

                                <p style={{ fontWeight: 'bold', fontSize: 15, height: 6 }} >

                                  {item1.faixa?.nome ? item1.faixa.nome?.replace('-', ' a ') + "" : ''}  <br />{parseFloat(item1?.cotacoesfaixa?.valor ? item1?.cotacoesfaixa?.valor.replace(',', '.') : (infoDependente?.length > 0 && infoDependente?.map((elemento) => (elemento <= item1?.faixa?.max && elemento >= item1?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item1?.faixa?.max && elemento >= item1?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1)} vida(s)
                                </p>

                              </td>

                            ))}

                            <td style={{ textAlign: 'center', borderWidth: 1, lineHeight: 1, padding: 5, border: 'none', width: 95 }}  >
                              <p style={{ fontWeight: 'bold', fontSize: 12, height: 20 }} >Total <br />{calculoDetalhe?.vidas > 0 ? calculoDetalhe.vidas : 1} vida(s)</p>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </tr>

                    {(item.faixas?.length > 0 || item.precovida) &&
                      <tr style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}   >
                        <td style={{ textAlign: 'center', padding: 5, width: '100%' }} >



                          <table className="table" style={{ cursor: 'pointer', marginBottom: 0 }}>
                            <tbody>

                              {item.status == 2 ?
                                <tr style={{ textAlign: 'center', borderWidth: 1 }}>
                                  Em atualização
                                </tr>
                                :
                                <>
                                  {(item.faixas?.length > 0 || item.precovida) &&
                                    <tr style={{ border: 0 }} >

                                      {item.faixas?.length ?
                                        <>
                                          {item?.faixas.filter((filter) => filter.cotacoesfaixa?.valor)?.map((item2) => (
                                            <>

                                              {item2.status ?
                                                <td style={{ textAlign: 'center', border: 0, color: '#092140', padding: 5, flex: 1, width: 95, borderTop: 0 }} >
                                                  <p style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1, height: 8, color: '#092140' }} >
                                                    {(
                                                      ((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * (item2.statuscoeficiente && item2.coeficiente ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : 0))

                                                      * parseFloat(item2?.cotacoesfaixa?.valor ? item2?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente?.length > 0 && infoDependente?.map((elemento) => (elemento <= item2?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                                    <span style={{ fontSize: 12 }} >

                                                      <br />{((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * (item2.statuscoeficiente && item2.coeficiente ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : '0'))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /> p/vida</span>
                                                  </p>


                                                </td>
                                                :
                                                <td style={{ textAlign: 'center', border: 0, flex: 1, width: 95 }} >
                                                  <p style={{ fontWeight: 'bold', color: 'red', fontSize: 15, height: 8 }} >Suspenso</p>

                                                </td>}
                                            </>

                                          ))}
                                          <td style={{ textAlign: 'center', border: 0, verticalAlign: 'middle', padding: 5, flex: 1, width: 95 }} >
                                            <p style={{ fontWeight: 'bold', fontSize: 16, height: 8, textAlign: 'center' }} className='valorTotal' >

                                              {Number(item?.faixas?.reduce((acumulador, item) => {
                                                return acumulador + ( (item.status? ((parseFloat(item.valor ? item.valor?.replace(',', '.') : 0) * (item?.statuscoeficiente && item?.coeficiente ? parseFloat(item?.coeficiente / 100) : 1)) + parseFloat(item.odonto ? item.odonto?.replace(',', '.') : '0')):0)
                                                  * parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente?.length > 0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))
                                              }, 0))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}




                                            </p>
                                            <span style={{ fontSize: 12 }} >Valor Total </span>
                                          </td>
                                        </>
                                        :
                                        <>
                                          {item.precovida &&
                                            <>
                                              <td style={{ textAlign: 'center', border: 0, padding: 5, flex: 1 }} >
                                                <p style={{ fontSize: 12 }} >R$ {item.precovida} p/vida</p>
                                              </td>
                                              <td style={{ textAlign: 'center', border: 0, padding: 5, flex: 1 }} >
                                                <p style={{ fontWeight: 'bold', fontSize: 15 }} className='valorTotal' >{(parseFloat(item.precovida.replace(',', '.')) * parseFloat(calculoDetalhe.vidas))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                              </td>
                                            </>

                                          }

                                        </>
                                      }


                                    </tr>
                                  }
                                </>

                              }

                            </tbody>
                          </table>
                        </td>
                      </tr>
                    }
                  </tr>
                ))}



              </tbody>
            </table>


            {info?.observacoes?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Observações Importantes</p>
                  <p style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br /> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                      {info && info?.observacoes?.map((item, index) => (
                        <>
                          <p  >
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }

            {info?.rede?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Rede de Atendimento</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.rede?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }
            {info?.coparticipacoes?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Coparticipação</p>

                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                      {info && info?.coparticipacoes?.map((item, index) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }

            {info?.reembolso?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Diferenciais e Reembolsos</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.reembolso?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }
            {info?.carencia?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Carências</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.carencia?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }



            {info?.dependente?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Dependentes</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}   >
                      {info && info?.dependente?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>

                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }


            {info?.documento?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Documentos Necessários</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.documento?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>


                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }

            {info?.pagamento?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Formas de Pagamentos</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.pagamento?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }



            {info?.entidades?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Entidades e Profissões  </p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.entidades?.map((item) => (
                        <div key={`entidades-${item.id}`} style={{ marginBottom: 20 }}>
                          <p style={{ fontWeight: 'bold' }} >
                            <p>
                              <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                            </p>
                          </p>

                          <span>{item?.item?.gruposentidades.map((item4) => (

                            <span key={item4.id}><span style={{ color: '#092140', fontWeight: 'bold', fontSize: 16 }}>{item4?.entidade?.nome}:</span>  {item4.entidadesprofisso?.profisso?.nome} - </span>

                          ))}</span>

                        </div>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }






            {info?.areacomercializacao?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Áreas de Comercialização e Atendimento  </p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >

                      {info && info?.areacomercializacao?.map((item) => (
                        <div key={`areacomercializacao-${item.id}`} style={{ marginBottom: 20 }} >
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>


                          {item?.municipios.filter((item, index, self) => index === self?.findIndex((m) => m.idestado === item?.idestado)).map((muni) => (
                            <>

                              {!info?.areacomercializacao[0].descricoes?.some((element) => (Number(element?.idestado) == muni.idestado && element.descricao)) ?

                                <>
                                  {item?.municipios.map((item3, index) => (
                                    <>
                                      <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3?.nome)} -` }}  ></span>
                                    </>
                                  ))}
                                </>
                                :
                                <>
                                  <span key={`Comercializacao-`}  >{info?.areacomercializacao[0].descricoes.find((element) => Number(element.idestado) == muni.idestado)?.descricao}</span><br />
                                  <a style={{ textDecoration: 'underline', textAlign: 'justify' }} onClick={() => { handleMunicipios(item?.municipios) }} > Ver municípios</a>
                                </>
                              }
                            </>
                          ))

                          }
                          <br />
                          <p  >{item.descricao}</p>

                        </div>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }





          </>

        }



      </div>
      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>

          <button
            type="button"
            onClick={() => {
              setModalMunicipios(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>

          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio.nome)} -` }}  ></span>
            ))}
          </Row>
        </div>

      </Modal>
    </Modal>
  )
}